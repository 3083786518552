import { useHistory } from "react-router";
import { EvaluatieScoreDetails, EvaluatieScores } from "../../api/types";
import { HeaderCell } from "../../components/SortableTable";
import { SortableTable } from "../../components/SortableTable";
var flatten = require("flat");

type ScoresOverviewTableProps = {
  scoreResult: EvaluatieScores;
  context: string;
};

export function ScoresOverviewTable({ scoreResult, context }: ScoresOverviewTableProps) {
  const history = useHistory();

  const mapScores = (scores: EvaluatieScoreDetails[]) => {
    var scoreObject = {} as any;

    for (var i = 0; i < scores.length; i++) {
      scoreObject[scores[i].rolName] = { score: scores[i].score, part: scores[i].part };
    }

    return flatten(scoreObject);
  };

  var mappedEvaluees = scoreResult.evaluees.map((evaluee) => {
    return {
      name: evaluee.name,
      personeeltoolId: evaluee.personeelstoolId,
      ...mapScores(evaluee.scores),
    };
  });

  var headerCells = mappedEvaluees
    .map((e) => Object.keys(e))
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index)
    .filter((value) => value !== "name" && value !== "personeeltoolId");

  const headers: HeaderCell<any>[] = [
    {
      id: "name",
      label: "Naam",
    },
    ...headerCells.map((header) => ({ id: header, label: header.endsWith("part") ? `Aandeel ${header.split(".")[0]}` : header.split(".")[0] })),
  ];

  return (
    <SortableTable<any>
      headers={headers}
      rows={mappedEvaluees}
      defaultSortedOn="name"
      defaultSortedDesc={false}
      detailAction={(row) => history.push(`/admin/scores/${context}/details/${row.personeeltoolId}`)}
    />
  );
}
