import React from "react";
import { Snackbar, IconButton, SnackbarContent, SnackbarCloseReason } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import CloseIcon from "@mui/icons-material/Close";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import styles from "./PopupMessage.module.scss";

export type PopupType = "success" | "error";

const icons: { [K in PopupType]: React.ComponentType<SvgIconProps> } = {
  success: SuccessIcon,
  error: ErrorIcon,
};

type Props = {
  open: boolean;
  onClose?: () => void;
  message: string;
  variant: PopupType;
};

export function PopupMessage({ open, onClose, message, variant = "success" }: Props) {
  const Icon = icons[variant];

  const handleClose = (_: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={variant === "success" ? 5000 : 15000}
      onClose={handleClose}
      className={variant === "success" ? styles.success : styles.error}
    >
      <SnackbarContent
        className={variant === "success" ? styles.success : styles.error}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={styles.message}>
            <Icon className={styles.icon} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
