import { RouteComponentProps } from "react-router";
import { QuestionDetail } from "./QuestionDetail";
import { useSaveQuestion } from "./useSaveQuestion";
import { useGetQuestionDetail } from "./useGetQuestionDetail";

type EditQuestionProps = RouteComponentProps<{
  questionKey: string;
  roleresponsibilityId: string;
}>;

export function EditQuestion(props: EditQuestionProps) {
  const { questionKey, roleresponsibilityId } = props.match.params;

  const saveQuestion = useSaveQuestion(roleresponsibilityId);

  const { data, isLoading } = useGetQuestionDetail(roleresponsibilityId, questionKey);

  if (isLoading || !data) return null;

  return <QuestionDetail key={questionKey} {...props} saveQuestion={saveQuestion} data={data} />;
}
