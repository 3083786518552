import { Checkbox, FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiClient, useApi } from "../../api/apiClient";
import { DeleteParameters, PostParameters, UserRole } from "../../api/types";
import Breadcrumb from "../../components/Breadcrumb";
import { GridItemLoadingAndDataChecker } from "../../components/GridItemLoadingAndDataChecker";

async function createUserRoleCall({ body }: PostParameters<"/userroles">) {
  return await apiClient["/userroles"].post({
    body: {
      type: "application/json",
      value: body,
    },
  });
}

async function deleteUserRoleCall({ body }: DeleteParameters<"/userroles">) {
  return await apiClient["/userroles"].delete({
    body: {
      type: "application/json",
      value: body,
    },
  });
}

function getUserRoles() {
  return apiClient["/userroles"].get({});
}

const emptyArray = [] as any[];
const orEmpty = <T extends any>(xs: T[] | undefined) => xs || (emptyArray as T[]);

export const Rechtenbeheer = React.memo(function () {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(["UserRoles"], useApi("Rechten ophalen", getUserRoles));

  const { mutate: addUserRole } = useMutation(useApi("Rol toekennen", createUserRoleCall), {
    onMutate: () => queryClient.cancelQueries("UserRoles"),
    onSettled: () => queryClient.invalidateQueries("UserRoles"),
  });

  const { mutate: deleteUserRole } = useMutation(useApi("Rol verwijderen", deleteUserRoleCall), {
    onMutate: () => queryClient.cancelQueries("UserRoles"),
    onSettled: () => queryClient.invalidateQueries("UserRoles"),
  });

  const handleCheckboxClick = useCallback(
    (role: string, personeeltoolId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const body = { body: { personeeltoolId: personeeltoolId, role: role } };
      if (checked) {
        addUserRole(body);
      } else {
        deleteUserRole(body);
      }
    },
    [addUserRole, deleteUserRole]
  );

  const roles = orEmpty(data?.possibleRoles);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb path={[{ name: "Home", route: "/" }]} currentPage="Rechtenbeheer" />
        </Grid>
        <Grid item xs={12}>
          <h1>Rechtenbeheer</h1>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="tableHeader">
                <TableRow>
                  <TableCell>Gebruiker</TableCell>
                  <TableCell>Rollen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.userRoles?.map((ur) => (
                  <RechtenbeheerRow key={ur.personeeltoolId} userRole={ur} roles={roles} handleCheckboxClick={handleCheckboxClick} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <GridItemLoadingAndDataChecker isLoading={isLoading} hasData={data && data.userRoles.length > 0} />
      </Grid>
    </>
  );
});

const MedewerkerCheckbox = () => <FormControlLabel control={<Checkbox checked disabled />} label="Medewerker" />;
const TeamAdminCheckbox = (props: { isTeamAdmin: boolean }) => <FormControlLabel control={<Checkbox checked={props.isTeamAdmin} disabled />} label="Teambeheerder" />;

type RechtenbeheerProps = {
  userRole: UserRole;
  roles: string[];
  handleCheckboxClick: (role: string, personeeltoolId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RechtenbeheerRow = React.memo(function ({ userRole, roles, handleCheckboxClick }: RechtenbeheerProps) {
  return (
    <TableRow className="tableRow" key={userRole.personeeltoolId}>
      <TableCell>{userRole.name}</TableCell>
      <TableCell className="smallPadding">
        {roles.map((r) => (
          <FormControlLabel
            key={`${userRole.personeeltoolId}-${r}`}
            control={<Checkbox checked={userRole.roles?.includes(r)} onChange={handleCheckboxClick(r, userRole.personeeltoolId!)} />}
            label={r}
          />
        ))}
        <TeamAdminCheckbox isTeamAdmin={userRole.isTeamAdmin!} />
        <MedewerkerCheckbox />
      </TableCell>
    </TableRow>
  );
});
