import { Alert, Divider, Grid, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Link as RouterLink } from "react-router-dom";
import styles from "./Home.module.scss";
import { MijnTeams } from "./teambeheerder/MijnTeams";
import { useUser } from "../components/UserContext";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { env } from "../env";

export function Home() {
  const { isAdmin, isGuildmaster, isMedewerker, isLoading, evaluationsToFillIn } = useUser();

  if (isLoading)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  if (!isMedewerker) return <Alert severity="error">Je hebt geen rechten om deze applicatie te gebruiken</Alert>;

  return (
    <>
      <Grid container>
        {isMedewerker && (
          <>
            <Grid item xs={12}>
              <Divider textAlign="left" className={styles.divider}>
                Medewerker
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Button component={RouterLink} to="/medewerker/myevaluations" variant="contained" color="primary" startIcon={<PersonIcon />} className={styles.menuButton}>
                Mijn evaluaties
              </Button>
              <Button
                component={RouterLink}
                to="/medewerker/evaluationstofillin"
                variant="contained"
                color="primary"
                startIcon={<PersonIcon />}
                className={styles.menuButton}
                disabled={evaluationsToFillIn === 0}
              >
                {evaluationsToFillIn === 0 ? "Geen" : evaluationsToFillIn > 99 ? "99+" : `${evaluationsToFillIn}`} in te vullen evaluaties
              </Button>
              <Button component={RouterLink} to="/medewerker/evaluationsifilledin" variant="contained" color="primary" startIcon={<PersonIcon />} className={styles.menuButton}>
                Evaluaties die ik ingevuld heb
              </Button>
              {env.REACT_APP_DISABLE_SCORES === "false" && (
                <Button component={RouterLink} to="/medewerker/scores" variant="contained" color="primary" startIcon={<AssessmentIcon />} className={styles.menuButton}>
                  Mijn Scores
                </Button>
              )}
            </Grid>
          </>
        )}
        {isMedewerker && <MijnTeams />}
        {(isGuildmaster || isAdmin) && (
          <>
            <Grid item xs={12}>
              <Divider textAlign="left" className={styles.divider}>
                Guildmaster
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Button component={RouterLink} to="/guildmaster/rollen" variant="contained" color="primary" startIcon={<SettingsIcon />} className={styles.menuButton}>
                Rollenbeheer
              </Button>
            </Grid>
          </>
        )}
        {isAdmin && (
          <>
            <Grid item xs={12}>
              <Divider textAlign="left" className={styles.divider}>
                Admin
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Button component={RouterLink} to="/admin/teambeheer" variant="contained" color="primary" startIcon={<ManageAccountsIcon />} className={styles.menuButton}>
                Teambeheer
              </Button>
              <Button component={RouterLink} to="/admin/rechtenbeheer" variant="contained" color="primary" startIcon={<ManageAccountsIcon />} className={styles.menuButton}>
                Rechtenbeheer
              </Button>
              <Button component={RouterLink} to="/admin/evaluaties" variant="contained" color="primary" startIcon={<ManageAccountsIcon />} className={styles.menuButton}>
                Evaluaties
              </Button>
              <Button component={RouterLink} to="/admin/scores" variant="contained" color="primary" startIcon={<AssessmentIcon />} className={styles.menuButton}>
                Scores
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
