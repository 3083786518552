import Breadcrumb from "../../components/Breadcrumb";
import EditIcon from "@mui/icons-material/Edit";
import { apiClient, useApi } from "../../api/apiClient";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Grid, Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { RouteComponentProps } from "react-router";
import { RoleDistributionFilter } from "./RoleDistributionFilter";
import { RoleDistributionRole, PutParameters } from "../../api/types";
import { GridItemLoadingAndDataChecker } from "../../components/GridItemLoadingAndDataChecker";
import { EditRoleDistribution } from "./EditRoleDistribution";
import { useUser } from "../../components/UserContext";

function getRoleDistribution(id: string) {
  return function () {
    return apiClient["/teams/{id}/roledistribution"].get({
      path: {
        id: id,
      },
    });
  };
}

function putUpdateRoleResponsibility({ path, body }: PutParameters<"/teams/{id}/roleresponsibility/{roleResponsibilityId}">) {
  return apiClient["/teams/{id}/roleresponsibility/{roleResponsibilityId}"].put({
    path: path,
    body: {
      type: "application/json",
      value: body,
    },
  });
}

type TeamDetailProps = RouteComponentProps<{
  aggregateKey: string;
}>;

export function RoleDistribution(props: TeamDetailProps) {
  const { aggregateKey } = props.match.params;
  const queryClient = useQueryClient();
  const { isAdmin } = useUser();

  const [filteredRoleDistributions, setFilteredRoleDistribution] = useState<RoleDistributionRole[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [roleResponsibility, setRoleResponsibility] = useState<RoleDistributionRole>();
  const [valuesToSave, setValuesToSave] = useState<string[]>([]);

  const { data, isLoading } = useQuery(["Team/roledistribution", aggregateKey], useApi("Role distribution", getRoleDistribution(aggregateKey)));

  const { mutate: updateRoleResponsibility } = useMutation(useApi("Update role responsibility", putUpdateRoleResponsibility), {
    onMutate: () => queryClient.cancelQueries("Team/roledistribution"),
    onSettled: () => queryClient.invalidateQueries("Team/roledistribution"),
  });

  const handleEdit = (responsibility: RoleDistributionRole) => {
    setRoleResponsibility(responsibility);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = (isCanceled: boolean) => {
    if (!isCanceled && roleResponsibility)
      updateRoleResponsibility({
        path: {
          id: aggregateKey,
          roleResponsibilityId: roleResponsibility!.blueprintRoleResponsibilityId,
        },
        body: valuesToSave,
      });

    setRoleResponsibility(undefined);
    setOpenEditDialog(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumb
          path={[
            { name: "Home", route: "/" },
            { name: "Mijn team", route: `/teambeheerder/mijnteam/${aggregateKey}` },
          ]}
          currentPage="Rolverdeling"
        />
      </Grid>
      <Grid item xs={12}>
        <h1>Rolverdeling</h1>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          {!isLoading && <RoleDistributionFilter roleDistributions={data?.roles ?? []} setFilteredRoleDistributions={setFilteredRoleDistribution} />}
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className="tableHeader">
              <TableRow>
                <TableCell>Fase</TableCell>
                <TableCell>Rol</TableCell>
                <TableCell>Rol-verantwoordelijkheid</TableCell>
                <TableCell>Toegewezen aan</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRoleDistributions?.map((t) => (
                <TableRow key={t.id} className="tableRow">
                  <TableCell>{t.phase}</TableCell>
                  <TableCell>{t.role}</TableCell>
                  <TableCell>{t.name}</TableCell>
                  <TableCell>{t.teamMembers.map((tm) => tm.name).join(", ")}</TableCell>
                  <TableCell>
                    {(isAdmin || data?.isMyTeam) && (
                      <IconButton onClick={() => handleEdit(t)}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    {t.urlDetailedInfo && (
                      <IconButton href={t.urlDetailedInfo} target="_blank">
                        <InfoIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {roleResponsibility && (
            <EditRoleDistribution
              open={openEditDialog}
              roleResponsibility={roleResponsibility}
              teamId={aggregateKey}
              handleClose={handleCloseEditDialog}
              valuesToSave={valuesToSave}
              setValuesToSave={setValuesToSave}
            />
          )}
        </TableContainer>
      </Grid>
      <GridItemLoadingAndDataChecker isLoading={isLoading} hasData={filteredRoleDistributions.length > 0} />
    </Grid>
  );
}
