import { apiClient, useApi } from "../api/apiClient";
import { useQuery } from "react-query";

function getRoles() {
  return apiClient["/roles"].get({});
}

export function useGetRoles() {
  return useQuery(["roles"], useApi("Rollen ophalen", getRoles));
}
