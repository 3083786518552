import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

type DeleteQuestionDialogProps = {
  open: boolean;
  handleClose: (isCancelled: boolean) => void;
  question: string;
};

export function DeleteQuestionDialog({ open, handleClose, question }: DeleteQuestionDialogProps) {
  return (
    <Dialog open={open} onClose={() => handleClose(true)}>
      <DialogTitle>Opgelet</DialogTitle>
      <DialogContent>Met deze actie verwijder je volgende vraag: {question}. Ben je zeker?</DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => handleClose(true)}>
          Annuleren
        </Button>
        <Button variant="contained" onClick={() => handleClose(false)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
