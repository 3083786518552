import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Role, RoleResponsibility } from "../../../api/types";
import { RoleDetailRow } from "./RoleDetailRow";

type RoleDetailTableProps = {
  data: RoleResponsibility[];
  expandedRowIds: string[];
  onToggle: (blueprintId: string) => void;
  roles: Role[];
  updateEvaluators: (blueprintId: string, evaluatorIds: string[]) => void;
  updateWeight: (blueprintId: string, weight: number) => void;
  updateIsActive: (blueprintId: string, isActive: boolean) => void;
};

export function RoleDetailTable({ data, expandedRowIds, onToggle, roles, updateEvaluators, updateWeight, updateIsActive }: RoleDetailTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className="tableHeader">
          <TableRow>
            <TableCell />
            <TableCell>Fase</TableCell>
            <TableCell>Naam</TableCell>
            <TableCell>Evaluatoren</TableCell>
            <TableCell>Gewicht</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d) => {
            const isExpanded = expandedRowIds.includes(d.blueprintId!);
            return (
              <RoleDetailRow
                key={d.blueprintId}
                data={d}
                isExpanded={isExpanded}
                onToggle={onToggle}
                roles={roles}
                updateEvaluators={updateEvaluators}
                updateWeight={updateWeight}
                updateIsActive={updateIsActive}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
