import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { RoleDistributionTeamMember, RoleDistributionRole } from "../../api/types";
import { MultiSelect } from "../../components/MultiSelect";
import { distinct } from "../../util";

type RoleDetailFilterProps = {
  roleDistributions: RoleDistributionRole[];
  setFilteredRoleDistributions: (roleRes: RoleDistributionRole[]) => void;
};

const empty = "empty";

const filterPhase =
  (selectedPhaseFilters: string[]) =>
  (rr: RoleDistributionRole): boolean => {
    if (!selectedPhaseFilters.length) return true;
    if (rr.phase) {
      return selectedPhaseFilters.includes(rr.phase);
    }
    return selectedPhaseFilters.includes(empty);
  };

const filterTeamMembers =
  (selectedTeamMemberFilters: string[]) =>
  (rr: RoleDistributionRole): boolean => {
    if (!selectedTeamMemberFilters.length) return true;
    if (rr.teamMembers && rr.teamMembers.length) {
      return rr.teamMembers.some((e) => selectedTeamMemberFilters.includes(e.id.toString()));
    }
    return selectedTeamMemberFilters.includes(empty);
  };

const filterRoleResponsibility =
  (selectedRoleResponsibilityFilters: string[]) =>
  (rr: RoleDistributionRole): boolean => {
    if (!selectedRoleResponsibilityFilters.length) return true;
    if (rr.name) {
      return selectedRoleResponsibilityFilters.includes(rr.name);
    }
    return selectedRoleResponsibilityFilters.includes(empty);
  };

const filterRole =
  (selectedRoleFilters: string[]) =>
  (rr: RoleDistributionRole): boolean => {
    if (!selectedRoleFilters.length) return true;
    if (rr.role) {
      return selectedRoleFilters.includes(rr.role);
    }
    return selectedRoleFilters.includes(empty);
  };

export function RoleDistributionFilter({ roleDistributions, setFilteredRoleDistributions }: RoleDetailFilterProps) {
  const [selectedPhaseFilters, setSelectedPhaseFilters] = useState<string[]>([]);
  const [selectedTeamMemberFilters, setSelectedTeamMemberFilters] = useState<string[]>([]);
  const [selectedRoleResponsibilityFilters, setSelectedRoleResponsibilityFilters] = useState<string[]>([]);
  const [selectedRoleFilters, setSelectedRoleFilters] = useState<string[]>([]);

  const phaseFilters = roleDistributions
    .map((rr) => rr.phase)
    .filter((p): p is string => !!p)
    .filter(distinct())
    .map((x) => ({ key: x, value: x }));
  phaseFilters.push({ key: empty, value: "(Geen)" });

  const teamMembersFilters = roleDistributions
    .map((rr) => rr.teamMembers)
    .filter((e): e is RoleDistributionTeamMember[] => !!e)
    .flat()
    .map((x) => ({ key: x.id!.toString(), value: x.name! }))
    .filter(distinct((x) => x.key))
    .sort((t1, t2) => (t1.value > t2.value ? 1 : -1));
  teamMembersFilters.push({ key: empty, value: "(Geen)" });

  const roleResponsibilityFilters = roleDistributions
    .map((rr) => rr.name)
    .filter((p): p is string => !!p)
    .filter(distinct())
    .map((x) => ({ key: x, value: x }))
    .sort((t1, t2) => (t1.value > t2.value ? 1 : -1));

  const roleFilters = roleDistributions
    .map((rr) => rr.role)
    .filter((p): p is string => !!p)
    .filter(distinct())
    .map((x) => ({ key: x, value: x }))
    .sort((t1, t2) => (t1.value > t2.value ? 1 : -1));

  useEffect(
    () =>
      setFilteredRoleDistributions(
        roleDistributions
          .filter(filterPhase(selectedPhaseFilters))
          .filter(filterTeamMembers(selectedTeamMemberFilters))
          .filter(filterRoleResponsibility(selectedRoleResponsibilityFilters))
          .filter(filterRole(selectedRoleFilters))
      ),
    [selectedPhaseFilters, selectedTeamMemberFilters, selectedRoleResponsibilityFilters, roleDistributions, setFilteredRoleDistributions, selectedRoleFilters]
  );

  const removeFilters = () => {
    setSelectedTeamMemberFilters([]);
    setSelectedPhaseFilters([]);
    setSelectedRoleResponsibilityFilters([]);
    setSelectedRoleFilters([]);
  };

  return (
    <>
      {roleDistributions.length > 0 && (
        <Stack direction="row" spacing={1}>
          {phaseFilters.length > 0 && <MultiSelect name="Fase" selectedItems={selectedPhaseFilters} setSelectedItems={setSelectedPhaseFilters} allItems={phaseFilters} />}
          {roleFilters.length > 0 && <MultiSelect name="Rol" selectedItems={selectedRoleFilters} setSelectedItems={setSelectedRoleFilters} allItems={roleFilters} />}
          {roleResponsibilityFilters.length > 0 && (
            <MultiSelect
              name="Rolverantwoordelijkheid"
              selectedItems={selectedRoleResponsibilityFilters}
              setSelectedItems={setSelectedRoleResponsibilityFilters}
              allItems={roleResponsibilityFilters}
            />
          )}
          {teamMembersFilters.length > 0 && (
            <MultiSelect name="Toegewezen aan" selectedItems={selectedTeamMemberFilters} setSelectedItems={setSelectedTeamMemberFilters} allItems={teamMembersFilters} />
          )}
          <Button color="secondary" variant="outlined" onClick={removeFilters}>
            Reset filters
          </Button>
        </Stack>
      )}
    </>
  );
}
