import { Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useQuery } from "react-query";
import { apiClient, useApi } from "../../api/apiClient";
import styles from "./AddTeamMemberDialog.module.scss";
import { MultiSelect } from "../../components/MultiSelect";
import { useState } from "react";

type AddTeamMemberDialogProps = {
  open: boolean;
  existingMembers: string[] | undefined;
  handleClose: (isCanceled: boolean) => void;
  setMemberIdsToAdd: (memberIds: string[]) => void;
  memberIdsToAdd: string[];
};

const getAllActiveQframers = () => {
  return apiClient["/people"].get({ query: { OnlyActive: true } });
};

export function AddTeamMemberDialog({ open, handleClose, setMemberIdsToAdd, memberIdsToAdd, existingMembers }: AddTeamMemberDialogProps) {
  const { data } = useQuery(["ActiveQframers"], useApi("Qframers ophalen", getAllActiveQframers));
  const allActiveQframers = data?.people?.map((p) => ({ key: p.id!, value: p.name! })) ?? [];
  const allActiveQframersNotYetInTeam = allActiveQframers.filter((p) => existingMembers?.findIndex((m) => m === p.value) === -1);
  const [errorMessage, setErrorMessage] = useState<string>();

  const updateMembersToAdd = (memberIds: string[]) => {
    if (memberIds.length === 0) {
      setErrorMessage("Gelieve minstens 1 nieuw teamlid te selecteren.");
    } else {
      setErrorMessage(undefined);
    }
    setMemberIdsToAdd(memberIds);
  };

  const closeModal = (isCancelled: boolean) => () => {
    setErrorMessage(undefined);
    handleClose(isCancelled);
  };

  return (
    <>
      <Dialog open={open} onClose={closeModal} className={styles.dialog}>
        <DialogTitle>Teamleden toevoegen</DialogTitle>
        <DialogContent>
          <Stack alignSelf="center" spacing={2} paddingTop={2} paddingBottom={2} direction={"row"}>
            <MultiSelect
              allItems={allActiveQframersNotYetInTeam}
              name="Teamleden"
              selectedItems={memberIdsToAdd}
              setSelectedItems={updateMembersToAdd}
              errorMessage={errorMessage}
            />
            <Stack alignSelf="end" spacing={2} paddingTop={2} paddingBottom={2} direction={"row"}>
              <Button variant="contained" color="secondary" onClick={closeModal(true)}>
                Annuleren
              </Button>
              <Button variant="contained" disabled={memberIdsToAdd.length === 0} onClick={closeModal(false)}>
                Bewaren
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
