import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { RouteComponentProps } from "react-router";
import { apiClient, useApi } from "../../../api/apiClient";
import { GetParameters, PostParameters, RoleResponsibility } from "../../../api/types";
import Breadcrumb from "../../../components/Breadcrumb";
import { GridItemLoadingAndDataChecker } from "../../../components/GridItemLoadingAndDataChecker";
import { RoleDetailFilter } from "./RoleDetailFilter";
import { RoleDetailTable } from "./RoleDetailTable";
import { useGetRoles } from "../../../hooks/useGetRoles";

type RoleDetailProps = RouteComponentProps<{
  id: string;
  roleresponsibilityId?: string;
}>;

const getExpandedRoleResponsibilities = (state: string) => {
  return !state ? [] : state.substring(1).split("|");
};

function getRoleResponsibilities({ query }: GetParameters<"/roleresponsibilities">) {
  return apiClient["/roleresponsibilities"].get({
    query: query,
  });
}

function postEvaluators({ body }: PostParameters<"/roleresponsibilities/evaluators">) {
  return apiClient["/roleresponsibilities/evaluators"].post({
    body: {
      type: "application/json",
      value: body,
    },
  });
}

function postWeight({ body }: PostParameters<"/roleresponsibilities/weight">) {
  return apiClient["/roleresponsibilities/weight"].post({
    body: {
      type: "application/json",
      value: body,
    },
  });
}

function setIsActive({ path }: PostParameters<"/roleresponsibilities/{blueprintRoleResponsibilityId}/activate">) {
  return apiClient["/roleresponsibilities/{blueprintRoleResponsibilityId}/activate"].post({ path: path });
}

function setIsInactive({ path }: PostParameters<"/roleresponsibilities/{blueprintRoleResponsibilityId}/inactivate">) {
  return apiClient["/roleresponsibilities/{blueprintRoleResponsibilityId}/inactivate"].post({ path: path });
}

export function RoleDetail(props: RoleDetailProps) {
  const { id } = props.match.params;
  const queryCache = useQueryClient();

  const [filteredRoleResponsibilities, setFilteredRoleResponsibilities] = useState<RoleResponsibility[]>([]);
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>(getExpandedRoleResponsibilities((props.location.hash ?? "") as string));

  const setExpandedRowIdsExt = useCallback(
    (rowIds: string[]) => {
      props.history.replace({ pathname: props.location.pathname, hash: rowIds.join("|") });
      setExpandedRowIds(rowIds);
    },
    [props.history, props.location.pathname]
  );

  const { data, isLoading } = useQuery(["getRoleDetail", id], useApi("Rolverantwoordelijkheden ophalen", () => getRoleResponsibilities({ query: { BlueprintRoleId: id } })));
  const { data: getRolesResponse } = useGetRoles();

  const updateExpandedRows = useCallback(
    (data: RoleResponsibility[], action: "open-all" | "close-all") => {
      if (action === "open-all") {
        setExpandedRowIdsExt(data.map((d) => d.blueprintId!));
      } else {
        setExpandedRowIdsExt([]);
      }
    },
    [setExpandedRowIdsExt]
  );

  const closeAll = useCallback(() => updateExpandedRows(filteredRoleResponsibilities, "close-all"), [filteredRoleResponsibilities, updateExpandedRows]);

  const expandAll = useCallback(() => updateExpandedRows(filteredRoleResponsibilities, "open-all"), [filteredRoleResponsibilities, updateExpandedRows]);

  const updateSingleExpandedRow = useCallback(
    (blueprintId: string) => {
      const isExpanded = expandedRowIds.includes(blueprintId);
      if (!isExpanded) {
        setExpandedRowIdsExt([...expandedRowIds, blueprintId]);
      } else {
        setExpandedRowIdsExt(expandedRowIds.filter((id) => id !== blueprintId));
      }
    },
    [expandedRowIds, setExpandedRowIdsExt]
  );

  const { mutate: updateEvaluatorsForRoleResponsibility } = useMutation(useApi("Update evaluators", postEvaluators), {
    onMutate: () => queryCache.cancelQueries("getRoleDetail"),
    onSettled: () => queryCache.invalidateQueries("getRoleDetail"),
  });

  const { mutate: updateWeightForRoleResponsibility } = useMutation(useApi("Update weight", postWeight), {
    onMutate: () => queryCache.cancelQueries("getRoleDetail"),
    onSettled: () => queryCache.invalidateQueries("getRoleDetail"),
  });

  const { mutate: activateRoleResponsibility } = useMutation(useApi("Rolverantwoordelijkheid actief zetten", setIsActive), {
    onMutate: () => queryCache.cancelQueries("getRoleDetail"),
    onSettled: () => queryCache.invalidateQueries("getRoleDetail"),
  });

  const { mutate: inactivateRoleResponsibility } = useMutation(useApi("Rolverantwoordelijkheid non-actief zetten", setIsInactive), {
    onMutate: () => queryCache.cancelQueries("getRoleDetail"),
    onSettled: () => queryCache.invalidateQueries("getRoleDetail"),
  });

  function updateEvaluators(blueprintId: string, evaluatorIds: string[]) {
    updateEvaluatorsForRoleResponsibility({ body: { blueprintRoleResponsibilityId: blueprintId, evaluatorIds: evaluatorIds } });
  }

  function updateWeight(blueprintId: string, weight: number) {
    updateWeightForRoleResponsibility({ body: { blueprintRoleResponsibilityId: blueprintId, weight } });
  }

  function handleUpdateIsActive(blueprintRoleResponsibilityId: string, isCurrentlyActive: boolean) {
    if (isCurrentlyActive) {
      return inactivateRoleResponsibility({
        path: {
          blueprintRoleResponsibilityId: blueprintRoleResponsibilityId,
        }
      });
    } else {
      return activateRoleResponsibility({
        path: {
          blueprintRoleResponsibilityId: blueprintRoleResponsibilityId,
        }
      });
    }
  }

  const roleResponsibilities = data?.roleResponsibilities ?? [];
  const roleName = data?.roleName ?? "";
  const roles = getRolesResponse?.roles || [];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb
            path={[
              { name: "Home", route: "/" },
              { name: "Rollen beheer", route: "/guildmaster/rollen" },
            ]}
            currentPage={roleName}
          />
        </Grid>
        <Grid item xs={12}>
          <h1>{roleName}</h1>
          <h2>Rolverantwoordelijkheden</h2>
          {!isLoading && <RoleDetailFilter roleResponsibilities={roleResponsibilities} setFilteredRoleResponsibilities={setFilteredRoleResponsibilities} />}
          <Tooltip title="Alles uitklappen">
            <IconButton size="large" onClick={expandAll}>
              <KeyboardArrowDownIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Alles inklappen">
            <IconButton size="large" onClick={closeAll}>
              <KeyboardArrowUpIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <RoleDetailTable
            data={filteredRoleResponsibilities}
            expandedRowIds={expandedRowIds}
            onToggle={updateSingleExpandedRow}
            roles={roles}
            updateEvaluators={updateEvaluators}
            updateWeight={updateWeight}
            updateIsActive={handleUpdateIsActive}
          />
        </Grid>
        <GridItemLoadingAndDataChecker isLoading={isLoading} hasData={filteredRoleResponsibilities.length > 0} />
      </Grid>
    </>
  );
}
