import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";

type GenerateEvaluationsDialogProps = {
  open: boolean;
  handleClose: (isCancelled: boolean, label?: string | null) => void;
  team: string;
};

export function GenerateEvaluationsDialog({ open, handleClose, team }: GenerateEvaluationsDialogProps) {
  const [label, setLabel] = useState<string | undefined>(undefined);
  const [hasLabel, setHasLabel] = useState(false);

  return (
    <Dialog open={open} onClose={() => handleClose(true)}>
      <DialogTitle>Evaluatieformulieren genereren</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            Met deze actie genereer je evaluatieformulieren voor team {team} (alles of enkel voor de geselecteerde teamleden). je kan - optioneel - extra context toevoegen. Gelieve
            dit enkel te doen indien er een specifieke context is voor de evaluatie buiten de gebruikelijke periodieke evaluaties om.
          </Grid>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid item xs={3}>
            <Typography>Extra context toevoegen ?</Typography>
          </Grid>
          <Grid item xs={1}>
            <Checkbox aria-label={"Label"} checked={hasLabel} onChange={(e) => setHasLabel(e.target.checked)} />
          </Grid>
          <Grid item xs={8}>
            <TextField style={{ display: hasLabel ? "inline" : "none" }} value={label} onChange={(e) => setLabel(e.target.value)} fullWidth={true} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => handleClose(true)}>
          Annuleren
        </Button>
        <Button variant="contained" onClick={() => handleClose(false, hasLabel ? label : null)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
