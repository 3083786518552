declare global {
  interface Window {
    env: Record<keyof EnvType, string>;
  }
}

type EnvType = {
  NODE_ENV: string;
  REACT_APP_API_HOST: string;
  REACT_APP_AUTH_CLIENTID: string;
  REACT_APP_DISABLE_GENERATE_EVALUATIONS: string;
  REACT_APP_DISABLE_SCORES: string;
};
export const env: EnvType = { ...process.env, ...window.env };
