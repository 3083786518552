import { apiClient, useApi } from "../../../api/apiClient";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import UpIcon from "@mui/icons-material/ArrowDropUp";
import DownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, IconButton, Stack, Switch } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { MouseEvent, useState } from "react";
import { GetParameters, PostParameters } from "../../../api/types";
import styles from "./RoleDetail.module.scss";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import { DeleteQuestionDialog } from "./DeleteQuestionDialog";

type QuestionsProps = {
  blueprintRoleResponsibilityId: string;
  roleResponsibilityName: string;
};

async function getQuestions(params: GetParameters<"/questions">) {
  return apiClient["/questions"].get({ query: params.query });
}

async function postMoveQuestion(params: PostParameters<"/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}/{direction}">) {
  return apiClient["/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}/{direction}"].post({ path: params.path });
}

async function postActivateQuestion(params: PostParameters<"/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}/activate">) {
  return apiClient["/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}/activate"].post({ path: params.path });
}

async function postInactivateQuestion(params: PostParameters<"/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}/inactivate">) {
  return apiClient["/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}/inactivate"].post({ path: params.path });
}

async function postDeleteQuestion(params: PostParameters<"/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}/delete">) {
  return apiClient["/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}/delete"].post({ path: params.path });
}

export function Questions({ blueprintRoleResponsibilityId }: QuestionsProps) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState<{ questionKey: string; question: string } | null>(null);

  const { data, isLoading } = useQuery(
    ["getQuestions", blueprintRoleResponsibilityId],
    useApi("Vragen ophalen", () => getQuestions({ query: { BlueprintRoleResponsibilityId: blueprintRoleResponsibilityId } }))
  );

  const { mutate: moveQuestion } = useMutation(useApi("Volgorde van de vraag aanpassen", postMoveQuestion), {
    onMutate: () => queryClient.cancelQueries("getQuestions"),
    onSettled: () => queryClient.invalidateQueries("getQuestions"),
  });

  const { mutate: activateQuestion } = useMutation(useApi("Vraag actief zetten", postActivateQuestion), {
    onMutate: () => queryClient.cancelQueries("getQuestions"),
    onSettled: () => queryClient.invalidateQueries("getQuestions"),
  });

  const { mutate: inactivateQuestion } = useMutation(useApi("Vraag non-actief zetten", postInactivateQuestion), {
    onMutate: () => queryClient.cancelQueries("getQuestions"),
    onSettled: () => queryClient.invalidateQueries("getQuestions"),
  });

  const { mutate: deleteQuestion } = useMutation(useApi("Vraag verwijderen", postDeleteQuestion), {
    onMutate: () => queryClient.cancelQueries("getQuestions"),
    onSettled: () => queryClient.invalidateQueries("getQuestions"),
  });

  const handleOpenDeleteDialog = (questionKey: string, question: string) => {
    setQuestionToDelete({ questionKey: questionKey, question: question });
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = (isCancelled: boolean) => {
    if (!isCancelled && data && questionToDelete) {
      deleteQuestion({
        path: {
          id: questionToDelete.questionKey,
          blueprintRoleResponsibilityId: blueprintRoleResponsibilityId,
        },
      });
    }
    setQuestionToDelete(null);
    setOpenDeleteDialog(false);
  };

  const questions = data?.questions || [];

  function handleMoveQuestion(e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>, questionKey: string, direction: "up" | "down") {
    e.preventDefault();

    return moveQuestion({
      path: {
        id: questionKey,
        blueprintRoleResponsibilityId: blueprintRoleResponsibilityId,
        direction: direction,
      },
    });
  }

  function handleEditButtonClick(key: string) {
    history.push(`/guildmaster/rolverantwoordelijkheden/${blueprintRoleResponsibilityId}/questions/${key}`);
  }

  const handleIsActiveChanged = (questionKey: string, isCurrentlyActive: boolean) => {
    if (isCurrentlyActive) {
      return inactivateQuestion({
        path: {
          id: questionKey,
          blueprintRoleResponsibilityId: blueprintRoleResponsibilityId,
        },
      });
    } else {
      return activateQuestion({
        path: {
          id: questionKey,
          blueprintRoleResponsibilityId: blueprintRoleResponsibilityId,
        },
      });
    }
  };

  return (
    <div>
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator isLoading={isLoading} />
      </Stack>
      <ul>
        {questions.map((q) => (
          <li key={q.aggregateKey}>
            <Grid container>
              <Grid item xs={9}>
                {q.title}
              </Grid>
              <Grid item xs={3}>
                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                  {q.isActive ? "Actief" : "Inactief"}
                  <Switch checked={q.isActive} onChange={() => handleIsActiveChanged(q.aggregateKey!, q.isActive!)} />
                  &nbsp; &nbsp;
                  <IconButton onClick={() => handleEditButtonClick(q.aggregateKey)}>
                    <EditIcon className={styles.iconButton} />
                  </IconButton>
                  &nbsp;
                  {!q.isActive && (
                    <>
                      <IconButton onClick={() => handleOpenDeleteDialog(q.aggregateKey, q.title)}>
                        <DeleteIcon className={styles.iconButton} />
                      </IconButton>
                      &nbsp;
                    </>
                  )}
                  <IconButton onClick={(e) => handleMoveQuestion(e, q.aggregateKey!, "up")}>
                    <UpIcon className={styles.iconButton} />
                  </IconButton>
                  <IconButton onClick={(e) => handleMoveQuestion(e, q.aggregateKey!, "down")}>
                    <DownIcon className={styles.iconButton} />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </li>
        ))}
      </ul>
      <Link to={`/guildmaster/rolverantwoordelijkheden/${blueprintRoleResponsibilityId}/questions/new`}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />}>
          Vraag toevoegen
        </Button>
      </Link>
      {data && questionToDelete && <DeleteQuestionDialog open={openDeleteDialog} question={questionToDelete.question} handleClose={handleCloseDeleteDialog} />}
    </div>
  );
}
