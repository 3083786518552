import Typography from "@mui/material/Typography";
import { Button, Card, Grid } from "@mui/material";
import { FormRoleResponsibilityDetail } from "./FormRoleResponsibilityDetail";
import styles from "./EditForm.module.scss";
import { DebouncedTextfield } from "../../components/DebouncedTextfield";
import { ConfirmSubmitFormDialog } from "./ConfirmSubmitFormDialog";
import { GetFormResponse, PostFormRoleResponsibility, PostParameters } from "../../api/types";
import { useEffect, useState } from "react";
import { apiClientWithPossibleAuth, useApi } from "../../api/apiClient";
import { useQueryClient, useMutation } from "react-query";

function postSaveQuestion({ path, body }: PostParameters<"/forms/{formKey}/questions/{questionKey}">) {
  return apiClientWithPossibleAuth["/forms/{formKey}/questions/{questionKey}"].post({
    path: path,
    body: {
      type: "application/json",
      value: body,
    },
  });
}

function postSaveForm({ path, body }: PostParameters<"/forms/{formKey}">) {
  return apiClientWithPossibleAuth["/forms/{formKey}"].post({
    path: path,
    body: {
      type: "application/json",
      value: body,
    },
  });
}

function postSubmitForm({ path }: PostParameters<"/forms/{formKey}/submit">) {
  return apiClientWithPossibleAuth["/forms/{formKey}/submit"].post({
    path: path,
  });
}

type EditFormAsEvaluatorProps = {
  formData: GetFormResponse;
};

export function EditFormAsEvaluator({ formData }: EditFormAsEvaluatorProps) {
  const queryClient = useQueryClient();
  const [expandedInitial, setExpandedInitial] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [confirmingSubmit, setConfirmingSubmit] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const handleSetExpanded = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? [...expanded, panel] : expanded.filter((e: string) => e !== panel));
  };

  useEffect(() => {
    if (formData && !expandedInitial) {
      setExpanded(formData!.roleResponsibilities.map((rr) => rr.name));
      setExpandedInitial(true);
    }
  }, [formData, expandedInitial]);

  useEffect(() => {
    const unansweredQuestionsExist = (formData?.roleResponsibilities.flatMap((rr) => rr.questions).filter((q) => q.isNvt === null && q.answer === null).length || 0) > 0;

    if (!unansweredQuestionsExist && !submitEnabled) {
      setSubmitEnabled(true);
    }
    if (unansweredQuestionsExist && submitEnabled) {
      setSubmitEnabled(false);
    }
  }, [formData, submitEnabled]);

  const { mutate: saveQuestion } = useMutation(useApi("Vraag opslaan", postSaveQuestion), {
    onMutate: () => queryClient.cancelQueries("Form/edit"),
    onSettled: () => queryClient.invalidateQueries("Form/edit"),
  });

  const { mutate: saveForm } = useMutation(useApi("Evaluatie opslaan", postSaveForm), {
    onMutate: () => queryClient.cancelQueries("Form/edit"),
    onSettled: () => queryClient.invalidateQueries("Form/edit"),
  });

  const { mutate: submitForm } = useMutation(useApi("Evaluatie indienen", postSubmitForm), {
    onMutate: () => queryClient.cancelQueries("Form/edit"),
    onSettled: () => queryClient.invalidateQueries("Form/edit"),
  });

  const handleSaveForm = () => {
    saveForm({
      path: { formKey: formData!.aggregateKey },
      body: {
        feedback: formData!.feedback,
        roleResponsibilities: formData!.roleResponsibilities.map(
          (rr) =>
            ({
              blueprintId: rr.blueprintId,
              roleResponsibilityVersion: rr.roleResponsibilityVersion,
              feedback: rr.feedback,
              questionAnswers: rr.questions,
            } as PostFormRoleResponsibility)
        ),
      },
    });
  };

  const handleSaveQuestion = (questionKey: string, answer: number | null, isNvt: boolean | null) => {
    saveQuestion({
      path: { formKey: formData!.aggregateKey, questionKey: questionKey },
      body: {
        answer: answer,
        isNvt: isNvt,
      },
    });
  };

  const handleSubmitForm = () => {
    setConfirmingSubmit(true);
  };

  const handleConfirmSubmit = (isCancelled: boolean) => {
    setConfirmingSubmit(false);

    if (isCancelled) return;

    submitForm({
      path: { formKey: formData!.aggregateKey },
    });
  };

  const handleRoleResponsibilityFeedback = (roleResponsibilityBlueprintId: string, feedback: string) => {
    var roleresponsibilities = [...formData.roleResponsibilities];
    var rrId = roleresponsibilities.findIndex((rr) => rr.blueprintId === roleResponsibilityBlueprintId);

    roleresponsibilities[rrId] = { ...roleresponsibilities[rrId], feedback: feedback };

    formData = { ...formData, roleResponsibilities: roleresponsibilities };
    handleSaveForm();
  };

  const handleFormFeedbackChange = (value: string) => {
    formData!.feedback = value;
    handleSaveForm();
  };

  return (
    <Grid container className={styles.container}>
      <Card className={styles.card}>
        <Grid container>
          <Grid item xs={12}>
            <a className={styles.logo} href="https://qframe.be/">
              <span className={styles.label}>Qframe</span>
            </a>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {formData.assessedPerson === formData.evaluator && `Dit is een zelfevaluatie voor ${formData.assessedPerson}.`}
              {formData.assessedPerson !== formData.evaluator && `Dit is een evaluatie voor ${formData.assessedPerson}, ingevuld door ${formData.evaluator}.`}
            </Typography>
          </Grid>
          {formData.roleResponsibilities.map((rr) => (
            <FormRoleResponsibilityDetail
              roleResponsibility={rr}
              expanded={expanded.indexOf(rr.name) !== -1}
              isDisabled={formData.disabled}
              setExpanded={handleSetExpanded}
              updateRoleResponsibility={(feedback) => handleRoleResponsibilityFeedback(rr.blueprintId, feedback)}
              key={`${rr.name}${rr.blueprintId}`}
              saveQuestion={handleSaveQuestion}
            />
          ))}
          <Grid item xs={12}>
            <DebouncedTextfield label={"Feedback"} value={formData.feedback} onChange={handleFormFeedbackChange} disabled={formData.disabled} className={styles.textbox} />
          </Grid>
          <Grid item xs={12} className={styles.submitButton}>
            <Button variant="contained" color="primary" onClick={handleSubmitForm} disabled={formData.disabled || !submitEnabled}>
              Evaluatie indienen
            </Button>
            <ConfirmSubmitFormDialog open={confirmingSubmit} handleClose={handleConfirmSubmit} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
