import { Box, Button, IconButton, Paper, Stack, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./SliderQuestionEditor.css";
import UpIcon from "@mui/icons-material/ArrowDropUp";
import DownIcon from "@mui/icons-material/ArrowDropDown";
import styles from "../roledetail/RoleDetail.module.scss";

export type SliderQuestionEditorProps = {
  title: string;
  setTitle: (title: string) => void;
  marks: { value: number; label: string }[];
  setMarks: (marks: { value: number; label: string }[]) => void;
};

type Mark = {
  value: number;
  label: string;
};

const marksDescendingByValue = (a: Mark, b: Mark) => (a.value === b.value ? 0 : a.value < b.value ? 1 : -1);
const marksAscendingByValue = (a: Mark, b: Mark) => (a.value === b.value ? 0 : a.value > b.value ? 1 : -1);

export function SliderQuestionEditor({ title, setTitle, marks, setMarks }: SliderQuestionEditorProps) {
  function onChangeBenchmark(value: number, label: string) {
    setMarks(marks.map((m) => (m.value === value ? { value: value, label: label } : m)));
  }

  function onAddBenchmark() {
    const tmpMarks = marks.sort(marksAscendingByValue);
    const totalMarks = tmpMarks.length;
    const newIncrement = 100 / totalMarks;

    const lastBenchmark = tmpMarks[totalMarks - 1];

    setMarks([
      ...tmpMarks
        .filter((m) => m.value !== 100)
        .map((m, index) => ({
          value: Math.floor(index * newIncrement),
          label: m.label,
        })),
      { value: Math.floor((totalMarks - 1) * newIncrement), label: "" },
      lastBenchmark,
    ]);
  }

  function onDeleteBenchmark(value: number) {
    if (marks.length < 3) {
      return;
    }

    const totalMarks = marks.length - 2;
    const newIncrement = 100 / totalMarks;

    setMarks(
      marks
        .filter((mark) => mark.value !== value)
        .sort(marksAscendingByValue)
        .map((m, i) => ({
          value: Math.floor(i * newIncrement),
          label: m.label,
        }))
    );
  }

  function onMoveBenchmark(value: number, direction: "up" | "down") {
    const indexToMove = marks.findIndex((m) => m.value === value);

    if (direction === "up") {
      if (value === 100) return;

      setMarks(swap(marks, indexToMove, indexToMove - 1));
    } else if (direction === "down") {
      if (value === 0) return;

      setMarks(swap(marks, indexToMove, indexToMove + 1));
    }
  }

  function swap(items: Mark[], index1: number, index2: number) {
    const tmpItems = items.slice();
    tmpItems[index1] = { ...items[index2], value: items[index1].value };
    tmpItems[index2] = { ...items[index1], value: items[index2].value };

    return tmpItems;
  }

  return (
    <Paper className={"container"}>
      <Box style={{ marginTop: "2rem" }}>
        <TextField
          style={{ width: "100%", marginBottom: "2rem" }}
          multiline={true}
          name={"questionTitle"}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          label={"Vraag titel"}
        />
      </Box>
      <Table style={{ marginBottom: "1rem" }}>
        <TableBody>
          {marks &&
            marks.sort(marksDescendingByValue).map((mark, index) => (
              <TableRow key={mark.value}>
                <TableCell style={{ width: "85%" }}>
                  <TextField label={"Ijkpunt"} value={mark.label} onChange={(e) => onChangeBenchmark(mark.value, e.target.value)} style={{ width: "100%" }} />
                </TableCell>
                <TableCell style={{ width: "10%" }}>
                  <TextField disabled={true} value={mark.value} style={{ width: "100%" }} />
                </TableCell>
                <TableCell style={{ width: "5%" }}>
                  <Stack direction={"row"} justifyContent={"flex-end"}>
                    <IconButton onClick={() => onMoveBenchmark(mark.value, "up")}>
                      <UpIcon className={styles.iconButton} />
                    </IconButton>
                    <IconButton onClick={() => onMoveBenchmark(mark.value, "down")}>
                      <DownIcon className={styles.iconButton} />
                    </IconButton>
                    <IconButton onClick={() => onDeleteBenchmark(mark.value)}>
                      <DeleteIcon className={styles.iconButton} />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Button variant={"contained"} onClick={onAddBenchmark}>
        Ijkpunt toevoegen
      </Button>
    </Paper>
  );
}
