import { TableHead, TableRow, TableCell, TableSortLabel, Box } from "@mui/material";
import { Order, HeaderCell } from "./SortableTable";
import { visuallyHidden } from "@mui/utils";
import { Fragment } from "react";

interface SortableTableHeaderProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: keyof T | undefined;
  rowCount: number;
  headers: HeaderCell<T>[];
  hasDetailButton: boolean;
}

export function SortableTableHeader<T>({ headers, order, orderBy, rowCount, onRequestSort, hasDetailButton }: SortableTableHeaderProps<T>) {
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="tableHeader">
      <TableRow>
        {headers.map((header) => (
          <Fragment key={`tableHeaderRow${header.label}${header.id}`}>
            {header.disableSorting && <TableCell key={`sortableHeaderCell${header.id}`}>{header.label}</TableCell>}
            {!header.disableSorting && (
              <TableCell key={`sortableHeaderCell${header.label}${header.id}`} sortDirection={orderBy === header.id ? order : false}>
                <TableSortLabel active={orderBy === header.id} direction={orderBy === header.id ? order : "asc"} onClick={createSortHandler(header.id)}>
                  {header.label}
                  {orderBy === header.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )}
          </Fragment>
        ))}
        {hasDetailButton && (
          <Fragment key={"tableHeaderRowDetailButton"}>
            <TableCell key={"sortableHeaderCellDetailButton"}></TableCell>
          </Fragment>
        )}
      </TableRow>
    </TableHead>
  );
}
