import DetailIcon from "@mui/icons-material/Search";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { apiClient, useApi } from "../../../api/apiClient";
import { RoleWithDiff } from "../../../api/types";
import Breadcrumb from "../../../components/Breadcrumb";
import { GridItemLoadingAndDataChecker } from "../../../components/GridItemLoadingAndDataChecker";
import styles from "./Rollenbeheer.module.scss";
import { formatUTCStringToLocal } from "./../../../helpers/dateHelpers";

async function postSetRoleInactive(blueprintId: string) {
  return await apiClient["/roles/setInactive"].post({
    query: { BlueprintRoleId: blueprintId },
  });
}

async function postStagingProjectAanpak() {
  return await apiClient["/blueprint/stage"].post({});
}

async function getRoles() {
  return await apiClient["/roles/all"].get({});
}

async function getStagingInfo() {
  return await apiClient["/blueprint/stagingInfo"].get({});
}

export function Rollenbeheer() {
  const queryCache = useQueryClient();
  const history = useHistory();
  const { data, isLoading } = useQuery(["getRoles"], useApi("Rollen ophalen", getRoles));
  const { data: stagingInfo } = useQuery(["getStagingInfo"], useApi("Staging info ophalen", getStagingInfo));

  const { mutate: setRoleInactive } = useMutation(useApi("Rol op inactief zetten", postSetRoleInactive), {
    onMutate: () => queryCache.cancelQueries("getRoles"),
    onSettled: () => queryCache.invalidateQueries("getRoles"),
  });

  const { mutate: stagingProjectAanpak, isLoading: executingStaging } = useMutation(useApi("Staging project aanpak", postStagingProjectAanpak), {
    onMutate: () => {
      queryCache.cancelQueries("getRoles");
      queryCache.cancelQueries("getStagingInfo");
    },
    onSettled: () => {
      queryCache.invalidateQueries("getRoles");
      queryCache.invalidateQueries("getStagingInfo");
    },
  });

  const roles: RoleWithDiff[] = data?.roles ?? [];

  function setInactive(data: RoleWithDiff) {
    if (!data.blueprintRoleId) return;
    setRoleInactive(data.blueprintRoleId);
  }

  function onRowClick(role: RoleWithDiff) {
    if (!role) return;
    if (role.status === "New" || role.status === "NotSynced") return;
    history.push(`/guildmaster/rollen/${role.blueprintRoleId}`);
  }

  function startStagingProjectAanpak() {
    stagingProjectAanpak();
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb path={[{ name: "Home", route: "/" }]} currentPage="Rollen beheer" />
        </Grid>
        <Grid item xs={12}>
          <h1>Rollen beheer</h1>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Als Guildmaster kunnen jullie vanuit deze pagina per rol rolverantwoordelijkheden toevoegen/verwijderen en bijhorende vragen aanpassen/toevoegen/verwijderen.
            <br /> Via het vergrootglas kom je in het overzicht van de rolverantwoordelijkheden van die rol. Indien er wijzigingen in deze tool gedetecteerd zijn t.o.v. de
            blauwdruk zal je deze als guildmaster in dit scherm nog moeten goed- of afkeuren.
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Grid item>
            <h2>Rollen</h2>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={startStagingProjectAanpak}>
              Sync met projectaanpak
            </Button>
            {stagingInfo?.date && <Typography>Laatste sync op {formatUTCStringToLocal(stagingInfo?.date)}</Typography>}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  <TableCell>Naam</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Rolverantwoordelijkheden wachtend op goedkeuring</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {roles.map((role) => (
                  <TableRow key={role.blueprintRoleId} className={styles.tableRow}>
                    <TableCell className={getStyle(role, styles.naam, styles.clickableNaam)} onClick={() => onRowClick(role)}>
                      {role.name}
                    </TableCell>
                    <TableCell className={getStyle(role, undefined, styles.clickable)} onClick={() => onRowClick(role)}>
                      {getStatus(role)}
                    </TableCell>
                    <TableCell>{getStatusAction(role, setInactive)}</TableCell>
                    <TableCell className={getStyle(role, undefined, styles.clickable)} onClick={() => onRowClick(role)}>
                      {role.status !== "New" && role.status !== "NotSynced" && <DetailIcon className={styles.clickableBlue} />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <GridItemLoadingAndDataChecker isLoading={isLoading || executingStaging} hasData={roles.length > 0} />
      </Grid>
    </>
  );
}

function getStyle(role: RoleWithDiff, normalStyle: string | undefined, clickableStyle: string) {
  if (role.status !== "New" && role.status !== "NotSynced") return clickableStyle;

  return normalStyle;
}

function getStatus(data: RoleWithDiff) {
  switch (data.status) {
    case "New":
      return "Nieuwe rol in blueprint";
    case "Deleted":
      return "Rol verwijderd in blueprint";
    case "Modified":
      return "Rolverantwoordelijkheden gewijzigd in blueprint";
    case "NotSynced":
      return "Niet gesynchroniseerd";
    case "Unchanged":
      return "Geen wijzigingen in blueprint";
  }
}

function getStatusAction(data: RoleWithDiff, setInactive: (data: RoleWithDiff) => void) {
  const syncRoleUri = `/guildmaster/rollen/${data.blueprintRoleId}/sync`;
  const isClickableTableElement = "isClickableTableElement";
  switch (data.status) {
    case "New":
      return (
        <Link className={isClickableTableElement} to={syncRoleUri}>
          Wijzigingen beoordelen ...
        </Link>
      );
    case "Deleted":
      return (
        <Button
          className={isClickableTableElement}
          onClick={(e) => {
            e.preventDefault();
            setInactive(data);
          }}
        >
          Verwijder rol
        </Button>
      );
    case "Modified":
      return (
        <Link className={isClickableTableElement} to={syncRoleUri}>
          Wijzigingen beoordelen ...
        </Link>
      );
    case "NotSynced":
      return (
        data.ignoredCount !== 0 && (
          <Link className={isClickableTableElement} to={syncRoleUri}>
            Alsnog toevoegen ...
          </Link>
        )
      );
    default:
      return (
        data.ignoredCount !== 0 && (
          <Link className={isClickableTableElement} to={syncRoleUri}>
            Resterende ...
          </Link>
        )
      );
  }
}
