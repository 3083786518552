import { useMutation, useQueryClient } from "react-query";
import { apiClient, useApi } from "../../../api/apiClient";
import { PutParameters } from "../../../api/types";

export type PutQuestionByIdParameters = PutParameters<"/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}">;

async function putQuestion({ path, body }: PutQuestionByIdParameters) {
  return await apiClient["/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}"].put({
    path: path,
    body: {
      type: "application/json",
      value: body,
    },
  });
}

export function useSaveQuestion(roleresponsibilityId: string, questionKey?: string, onSettled?: () => void){
  const queryClient = useQueryClient();

  const { mutateAsync: saveQuestion } = useMutation(useApi("Vraag bewaren", putQuestion), {
    onMutate: async () => {
      await queryClient.cancelQueries(["getQuestions"]);
      await queryClient.cancelQueries(["getQuestionDetail", questionKey, roleresponsibilityId]);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(["getQuestions"]);
      await queryClient.invalidateQueries(["getQuestionDetail", questionKey, roleresponsibilityId]);

      onSettled && onSettled();
    },
  });

  return saveQuestion;
}