import { SelectChangeEvent, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, FormHelperText } from "@mui/material";

type MultiSelectProps = {
  name: string;
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
  allItems: { key: string; value: string }[];
  errorMessage?: string;
  width?: number;
};

export function MultiSelect({ name, selectedItems, setSelectedItems, allItems, errorMessage, width }: MultiSelectProps) {
  const allKey = "__all__";
  const allItemsAreSelected = selectedItems.length > 0 && selectedItems.length === allItems.length;

  const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const {
      target: { value },
    } = event;
    const valueArray = typeof value === "string" ? [value] : value;
    if (valueArray.includes(allKey)) {
      setSelectedItems(allItemsAreSelected ? [] : allItems.map((x) => x.key));
    } else {
      setSelectedItems(valueArray);
    }
  };

  return (
    <FormControl sx={{ width: width ?? 300 }}>
      <InputLabel>{name}</InputLabel>
      <Select
        multiple
        value={selectedItems}
        renderValue={(selected) => {
          return selected.map((s) => allItems.find((item) => item.key === s)?.value).join(", ");
        }}
        onChange={handleChange}
        input={<OutlinedInput label={name} />}
        error={!!errorMessage}
      >
        <MenuItem value={allKey} key={allKey}>
          <Checkbox checked={allItemsAreSelected} />
          <ListItemText primary="Selecteer alles" />
        </MenuItem>
        {allItems.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            <Checkbox checked={selectedItems.includes(item.key)} />
            <ListItemText primary={item.value} />
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
