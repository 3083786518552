import { PropsWithChildren } from "react";
import NavBar from "./NavBar";

type PageLayoutProps = PropsWithChildren<{}>;

export function PageLayout(props: PageLayoutProps) {
  return (
    <>
      <NavBar />
      <br />
      {props.children}
    </>
  );
}
