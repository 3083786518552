import { Button, Grid, Paper, Stack } from "@mui/material";
import Breadcrumb from "../../../components/Breadcrumb";
import { RouteComponentProps } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { SliderQuestion } from "../../../components/SliderQuestion";
import { SliderQuestionEditor } from "./SliderQuestionEditor";
import { QuestionDetailType, QuestionDetailResponse } from "../../../api/types";
import { useHistory } from "react-router-dom";
import { PutQuestionByIdParameters } from "./useSaveQuestion";

type QuestionDetailProps = RouteComponentProps<{
  questionKey?: string;
  roleresponsibilityId: string;
}> & {
  saveQuestion: (params: PutQuestionByIdParameters) => void;
  data: QuestionDetailResponse;
};

type QuestionData = {
  questionKey: string;
  title: string;
  blueprintRoleResponsibilityId: string;
  marks: { value: number; label: string }[];
};

export function QuestionDetail(props: QuestionDetailProps) {
  const { questionKey: questionKeyParam, roleresponsibilityId } = props.match.params;
  const { saveQuestion, data } = props;

  const [modifiedQuestionData, setModifiedQuestionData] = useState<QuestionData>();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const history = useHistory();
  const [questionKey, setQuestionKey] = useState(questionKeyParam);

  const onCancel = useCallback(
    () => history.push(`/guildmaster/rollen/${data?.metadata?.blueprintRoleId}#${data?.metadata?.blueprintRoleResponsibilityId}`),
    [history, data?.metadata]
  );

  async function onSaveAndAddQuestionFun() {
    if (saveInProgress) return;

    setSaveInProgress(true);

    await saveQuestion({
      path: {
        blueprintRoleResponsibilityId: modifiedQuestionData!.blueprintRoleResponsibilityId,
        id: modifiedQuestionData!.questionKey,
      },
      body: {
        title: modifiedQuestionData!.title,
        benchmarks: modifiedQuestionData!.marks,
      },
    });

    history.push(`/guildmaster/rolverantwoordelijkheden/${roleresponsibilityId}/questions/new`);
  }

  async function onSaveAndReturnFun() {
    if (saveInProgress) return;

    setSaveInProgress(true);

    await saveQuestion({
      path: {
        blueprintRoleResponsibilityId: modifiedQuestionData!.blueprintRoleResponsibilityId,
        id: modifiedQuestionData!.questionKey,
      },
      body: {
        title: modifiedQuestionData!.title,
        benchmarks: modifiedQuestionData!.marks,
      },
    });

    history.push(`/guildmaster/rollen/${data?.metadata?.blueprintRoleId}#${data?.metadata?.blueprintRoleResponsibilityId}`);
  }

  async function onSaveFun() {
    if (saveInProgress) return;

    setSaveInProgress(true);

    await saveQuestion({
      path: {
        blueprintRoleResponsibilityId: modifiedQuestionData!.blueprintRoleResponsibilityId,
        id: modifiedQuestionData!.questionKey,
      },
      body: {
        title: modifiedQuestionData!.title,
        benchmarks: modifiedQuestionData!.marks,
      },
    });

    const isNew = !data?.questionDetail;
    if (isNew) {
      const editRoute = `/guildmaster/rolverantwoordelijkheden/${roleresponsibilityId}/questions/${modifiedQuestionData!.questionKey}`;
      history.push(editRoute);
    } else {
      setSaveInProgress(false);
    }
  }

  const onSave = useCallback(onSaveFun, [data?.questionDetail, history, modifiedQuestionData, roleresponsibilityId, saveInProgress, saveQuestion]);
  const onSaveAndAddQuestion = useCallback(onSaveAndAddQuestionFun, [history, modifiedQuestionData, roleresponsibilityId, saveInProgress, saveQuestion]);
  const onSaveAndReturn = useCallback(onSaveAndReturnFun, [
    data?.metadata?.blueprintRoleId,
    data?.metadata?.blueprintRoleResponsibilityId,
    history,
    modifiedQuestionData,
    saveInProgress,
    saveQuestion,
  ]);

  useEffect(() => {
    if (!modifiedQuestionData || modifiedQuestionData.questionKey !== questionKey) {
      if (!data.questionDetail?.questionKey) {
        const newQuestionKey = uuidv4();
        setQuestionKey(newQuestionKey);
        setModifiedQuestionData({
          title: "Nieuwe vraag",
          questionKey: newQuestionKey,
          blueprintRoleResponsibilityId: roleresponsibilityId,
          marks: getMarks({}),
        });
      } else {
        setModifiedQuestionData({
          title: data.questionDetail.title!,
          questionKey: data.questionDetail.questionKey,
          blueprintRoleResponsibilityId: roleresponsibilityId,
          marks: getMarks(data.questionDetail),
        });
      }
    }

    function getMarks(questionDetail: Partial<QuestionDetailType>) {
      const defaultMarks = [
        { value: 0, label: "minimum" },
        { value: 100, label: "maximum" },
      ];

      if ((questionDetail.benchmarks?.length ?? 0) < 2) return defaultMarks;

      return questionDetail.benchmarks?.map((b) => ({ label: b.label ?? "", value: b.value ?? 0 })) || defaultMarks;
    }
  }, [setModifiedQuestionData, modifiedQuestionData, data, roleresponsibilityId, questionKey]);

  if (!modifiedQuestionData) return null;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb
            path={[
              { name: "Home", route: "/" },
              { name: "Rollen beheer", route: "/guildmaster/rollen" },
              {
                name: `${data?.metadata?.roleName}`,
                route: `/guildmaster/rollen/${data?.metadata?.blueprintRoleId}#${data?.metadata?.blueprintRoleResponsibilityId}`,
              },
            ]}
            currentPage={modifiedQuestionData.title}
          />
        </Grid>
        <Grid item xs={12}>
          <h2>{data?.metadata?.roleResponsibilityName}</h2>
          <h1>Vraag: {modifiedQuestionData.title}</h1>
        </Grid>
        <Grid item xs={12}>
          <SliderQuestionEditor
            title={modifiedQuestionData.title}
            marks={modifiedQuestionData.marks}
            setTitle={(title) => setModifiedQuestionData({ ...modifiedQuestionData, title: title })}
            setMarks={(marks) => setModifiedQuestionData({ ...modifiedQuestionData, marks: marks })}
          />
        </Grid>
        <Grid item xs={12}>
          <h2>Live voorbeeld:</h2>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: "1rem 3rem", marginBottom: "2rem" }}>
            <SliderQuestion marks={modifiedQuestionData.marks} selectedValue={null} isNvt={null} title={modifiedQuestionData.title} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} spacing={2}>
            <Button disabled={saveInProgress} variant="contained" color="primary" onClick={() => onSave()}>
              Opslaan
            </Button>
            <Button disabled={saveInProgress} variant="contained" color="primary" onClick={() => onSaveAndReturn()}>
              Opslaan en terug naar overzicht
            </Button>
            <Button disabled={saveInProgress} variant="contained" color="primary" onClick={() => onSaveAndAddQuestion()}>
              Opslaan en nog een vraag toevoegen
            </Button>
            <Button disabled={saveInProgress} variant="contained" color="secondary" onClick={onCancel}>
              Annuleren
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
