function Colours() {
  return {
    tableCellTextColour: "#5B5B5B",
    tableBorderColour: "#5B5B5B",
    tableHeaderBackgroundColour: "#D8D8D8",
    tableHeaderTextColour: "#5B5B5B",
    rowBackgroundColour: "#F3F3F3",
    rowBackgroundColourDisapproved: "#FF0000",
    rowTextColourDisapproved: "#FFFFFF",
  };
}

export default Colours();
