import { Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { apiClient, useApi } from "../../api/apiClient";
import { MultiSelect } from "../../components/MultiSelect";
import { RoleDistributionRole } from "../../api/types";

type EditRoleDistributionDialogProps = {
  teamId: string;
  roleResponsibility: RoleDistributionRole;
  open: boolean;
  handleClose: (isCanceled: boolean) => void;
  setValuesToSave: (setParamsToSave: string[]) => void;
  valuesToSave: string[];
};

function getTeam(teamId: string) {
  return function () {
    return apiClient["/teams/{id}"].get({ path: { id: teamId } });
  };
}

export function EditRoleDistribution({ teamId, roleResponsibility, open, handleClose, setValuesToSave, valuesToSave }: EditRoleDistributionDialogProps) {
  const { data } = useQuery(["GetTeam", teamId], useApi("Team ophalen", getTeam(teamId)));
  const teamMembers = data?.teamMembers?.map((p) => ({ key: p.personeeltoolId!, value: p.name! })) ?? [];

  useEffect(() => {
    setValuesToSave(roleResponsibility.teamMembers.map((tm) => tm.personeeltoolId));
  }, [roleResponsibility, setValuesToSave]);

  const setTeamMembers = (selectedTeamMembers: string[]) => {
    setValuesToSave(selectedTeamMembers);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{roleResponsibility.name}</DialogTitle>
      <DialogContent>
        <Stack alignSelf="center" spacing={2} paddingTop={2} paddingBottom={2} direction={"row"}>
          <MultiSelect allItems={teamMembers} name="Team" selectedItems={valuesToSave} setSelectedItems={setTeamMembers} />
          <Stack alignSelf="end" spacing={2} paddingTop={2} paddingBottom={2} direction={"row"}>
            <Button variant="contained" color="secondary" onClick={() => handleClose(true)}>
              Annuleren
            </Button>
            <Button variant="contained" onClick={() => handleClose(false)}>
              Bewaren
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
