import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import { MultiSelect } from "../../../components/MultiSelect";

type EvaluatorDialogProps = {
  open: boolean;
  name: string;
  selectedRoles: string[];
  setSelectedRoles: (roles: string[]) => void;
  allRoles: {
    key: string;
    value: string;
  }[];
  handleClose: (isCanceled: boolean) => () => void;
};

export function EvaluatorDialog({ open, name, selectedRoles, setSelectedRoles, allRoles, handleClose }: EvaluatorDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Evaluatoren toevoegen</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: "1rem" }}>{name}</DialogContentText>
        <Stack alignSelf="center" spacing={2} paddingTop={2} paddingBottom={2} direction={"row"}>
          <MultiSelect allItems={allRoles} name="Evaluator" selectedItems={selectedRoles} setSelectedItems={setSelectedRoles} />
          <Stack alignSelf="end" spacing={2} paddingTop={2} paddingBottom={2} direction={"row"}>
            <Button variant="contained" color="secondary" onClick={handleClose(true)}>
              Annuleren
            </Button>
            <Button variant="contained" onClick={handleClose(false)}>
              Bewaren
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
