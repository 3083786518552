import { useEffect, useState } from "react";

// Msal imports
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../auth/authConfig";
import { GetBearer } from "../auth/auth";

// Material-ui imports
import { Typography } from "@mui/material";

export function Profile() {
  const authRequest = {
    ...loginRequest,
  };

  const [bearer, setBearer] = useState<string>();

  useEffect(() => {
    GetBearer().then((response) => setBearer(response ?? ""));
  });

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      {bearer}
    </MsalAuthenticationTemplate>
  );
}

export const Loading = () => {
  return <Typography variant="h6">Authentication in progress...</Typography>;
};

export function ErrorComponent({ error }: MsalAuthenticationResult) {
  return (
    <Typography variant="h6">
      An Error Occurred: {error ? error.errorCode : "unknown error"}
    </Typography>
  );
}
