import React, { PropsWithChildren, useState } from "react";
import { PopupType } from "./PopupMessage";

export const SnackbarContext = React.createContext<{
  msg: string;
  isDisplayed: boolean;
  displayMsg: (msg: string, variant: PopupType) => void;
  onClose?: () => void;
  variant: PopupType;
}>(undefined as any);

export const SnackBarContextProvider = (props: PropsWithChildren<{}>) => {
  const [msg, setMsg] = useState("");
  const [variant, setVariant] = useState<PopupType>("success");
  const [isDisplayed, setIsDisplayed] = useState(false);

  const displayHandler = (msg: string, variant: PopupType) => {
    setMsg(msg);
    setVariant(variant);
    setIsDisplayed(true);
  };

  return (
    <SnackbarContext.Provider
      value={{
        msg,
        isDisplayed,
        displayMsg: displayHandler,
        onClose: () => setIsDisplayed(false),
        variant,
      }}
    >
      {props.children}
    </SnackbarContext.Provider>
  );
};
