import React, { PropsWithChildren, useContext } from "react";
import { apiClient, useApi } from "../api/apiClient";
import { useQuery } from "react-query";

export enum ApplicationRole {
  Admin = "Admin",
  Guildmaster = "Guildmaster",
  Medewerker = "Medewerker",
}

const getUser = () => {
  return apiClient["/user"].get({});
};

const UserContext = React.createContext({ isAdmin: false, isGuildmaster: false, isMedewerker: false, roles: [] as string[], evaluationsToFillIn: 0, isLoading: true });

export const UserContextProvider = (props: PropsWithChildren<{}>) => {
  const { data, isLoading } = useQuery(["User"], useApi("Gebruiker ophalen", getUser));
  return (
    <UserContext.Provider
      value={{
        isAdmin: data?.roles?.includes(ApplicationRole.Admin) ?? false,
        isGuildmaster: data?.roles?.includes(ApplicationRole.Guildmaster) ?? false,
        isMedewerker: data?.roles?.includes(ApplicationRole.Medewerker) ?? false,
        roles: data?.roles ?? [],
        evaluationsToFillIn: data?.evaluationsToFillIn ?? 0,
        isLoading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export function useUser() {
  const userContext = useContext(UserContext);

  return {
    isAdmin: userContext.isAdmin,
    isGuildmaster: userContext.isGuildmaster,
    isMedewerker: userContext.isMedewerker,
    roles: userContext.roles,
    evaluationsToFillIn: userContext.evaluationsToFillIn,
    isLoading: userContext.isLoading,
  };
}
