import { RouteComponentProps } from "react-router";
import { QuestionDetail } from "./QuestionDetail";
import { useSaveQuestion } from "./useSaveQuestion";
import { useGetQuestionDetail } from "./useGetQuestionDetail";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

type NewQuestionProps = RouteComponentProps<{
  roleresponsibilityId: string;
}>;

export function NewQuestion(props: NewQuestionProps) {
  const roleresponsibilityId = props.match.params.roleresponsibilityId;
  const [newQuestionkey, setNewQuestionkey] = useState<string>(uuidv4());

  const saveQuestion = useSaveQuestion(roleresponsibilityId, undefined, () => setNewQuestionkey(uuidv4()));

  const { data, isLoading } = useGetQuestionDetail(roleresponsibilityId);

  if (isLoading || !data) return null;

  return <QuestionDetail key={newQuestionkey} {...props} saveQuestion={saveQuestion} data={data} />;
}
