import { env } from "../env";
import { clientFor } from "./api.generic";
import { components, paths } from "./types.generated";
import { GetBearer } from "../auth/auth";
import { useContext } from "react";
import { SnackbarContext } from "../components/SnackbarContext";
import { handleApiResponse } from "./handleApiResponse";

export const apiClient = getApiClient();
export const apiClientWithPossibleAuth = getApiClientWithPossibleAuth();
export const apiClientForDownloads = getApiClientForDownload();

export type Error = components["schemas"]["Microsoft.AspNetCore.Mvc.ProblemDetails"];

function getApiClient() {
  return clientFor<paths>()(env.REACT_APP_API_HOST, "application/json", async () => {
    const token = await GetBearer();
    if (!token) throw new Error("no token received");
    return { Authorization: token };
  });
}

function getApiClientForDownload() {
  return clientFor<paths>()(env.REACT_APP_API_HOST, "blob", async () => {
    const token = await GetBearer();
    if (!token) throw new Error("no token received");
    return { Authorization: token };
  });
}

function getApiClientWithPossibleAuth() {
  return clientFor<paths>()(env.REACT_APP_API_HOST, "application/json", async () => {
    const token = await GetBearer();
    if (!token) return { Authorization: "" };
    return { Authorization: token };
  });
}

function getValidationErrors(error?: Error) {
  if (!error || !error.errors) return "";

  const validationErrors = error["errors"] as Record<string, string>;
  const validationErrorKeys = Object.keys(validationErrors);

  return validationErrorKeys.map((key) => `${key}: ${validationErrors[key]}`).join("\n");
}

export function useApi<T extends { type: number; value: any }, B extends any[]>(action: string, call: (...args: B) => Promise<T>) {
  const snackbarCtx = useContext(SnackbarContext);

  return async (...args: B) => {
    const result = await call(...args);
    return handleApiResponse(
      result,
      action,
      (message) => snackbarCtx.displayMsg(message, "success"),
      (code, error) => snackbarCtx.displayMsg(`${code.toString()}: ${error?.title || ""} ${error?.detail || ""}\n${getValidationErrors(error)}`, "error")
    );
  };
}
