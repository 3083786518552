import { Button, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { apiClient, apiClientForDownloads, useApi } from "../../api/apiClient";
import Breadcrumb from "../../components/Breadcrumb";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ScoresOverviewTable } from "./ScoresOverviewTable";

const getAllContexts = () => {
  return apiClient["/forms/context"].get({});
};

function getContextDetails(context: string) {
  return function () {
    return apiClient["/forms/context"].post({
      body: {
        type: "application/json",
        value: context,
      },
    });
  };
}

async function downloadContextExcel(context: string) {
  return apiClientForDownloads["/forms/context/excel"]
    .post({
      body: {
        type: "application/json",
        value: context,
      },
    })
    .then((response: any) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.value);
      link.setAttribute("download", `Resultaten_Evaluaties_${context}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
}

export const ScoresOverview = React.memo(function () {
  const [selectedContext, setSelectedContext] = useState<string>("");

  const { data: allContextResults, isLoading: contextsAreLoading } = useQuery(["AllEvaluationContexts"], useApi("Alle contexten ophalen", getAllContexts));
  const { data: contextDetails, isLoading: detailsAreLoading } = useQuery(
    ["ContextDetails", selectedContext],
    useApi("Details voor evaluatieronde ophalen", getContextDetails(selectedContext))
  );

  const allFormContexts = allContextResults?.formContexts?.map((p) => ({ key: p, value: p })) ?? [];

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedContext(event.target.value as string);
  };

  if (contextsAreLoading || !allContextResults)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumb path={[{ name: "Home", route: "/" }]} currentPage="Scores" />
        </Grid>
        <Grid item xs={12}>
          <h1>Scores</h1>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Hieronder kan je een evaluatie-"context" selecteren om zo het overzicht van de scores te krijgen. Enkel als alle evaluaties van een "context" ingevuld zijn of op
            ignored staan komt deze bij in de lijst te staan.
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControl sx={{ width: 300 }}>
            <InputLabel>Selecteer een context</InputLabel>
            <Select labelId="context" id="context" value={selectedContext} label="Evaluatie context" onChange={handleChange}>
              <MenuItem key="" value="&nbsp;">
                <ListItemText primary="&nbsp;" />
              </MenuItem>
              {allFormContexts.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  <ListItemText primary={item.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {detailsAreLoading || !contextDetails ? (
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="center">
              <LoadingIndicator />
            </Stack>
          </Grid>
        ) : (
          <>
            <Grid item xs={2}>
              <Button disabled={selectedContext === ""} variant="contained" onClick={() => downloadContextExcel(selectedContext)}>
                Download resultaten
              </Button>
            </Grid>
            {selectedContext === "" && (
              <Grid item xs={12}>
                Geen evaluaties geselecteerd
              </Grid>
            )}
            {selectedContext !== "" && contextDetails && (
              <Grid item xs={12}>
                <ScoresOverviewTable scoreResult={contextDetails} context={selectedContext} />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
});
