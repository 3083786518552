import { Button, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { FormForAdminForm } from "../../api/types";
import { MultiSelect } from "../../components/MultiSelect";
import { distinct } from "../../util";
import { DateRangePicker } from "../../components/DateRangePicker";
import { parse } from "date-fns";

type EvaluatiesOverviewFilterProps = {
  evaluations: FormForAdminForm[];
  setFilteredEvaluations: (roleRes: FormForAdminForm[]) => void;
};

const empty = "empty";

const filterEmployee =
  (selectedEmployeeFilters: string[]) =>
  (form: FormForAdminForm): boolean => {
    if (!selectedEmployeeFilters.length) return true;
    if (form.assessPersonName) {
      return selectedEmployeeFilters.includes(form.assessPersonName);
    }
    return selectedEmployeeFilters.includes(empty);
  };

const filterEvaluator =
  (selectedEvaluatorFilters: string[]) =>
  (form: FormForAdminForm): boolean => {
    if (!selectedEvaluatorFilters.length) return true;
    if (form.evaluatorName) {
      return selectedEvaluatorFilters.includes(form.evaluatorName);
    }
    return selectedEvaluatorFilters.includes(empty);
  };

const filterTeam =
  (selectedTeamFilters: string[]) =>
  (form: FormForAdminForm): boolean => {
    if (!selectedTeamFilters.length) return true;
    if (form.teamName) {
      return selectedTeamFilters.includes(form.teamName);
    }
    return selectedTeamFilters.includes(empty);
  };

const filterPeriode =
  (selectedPeriodeFilters: string[]) =>
  (form: FormForAdminForm): boolean => {
    if (!selectedPeriodeFilters.length) return true;
    if (form.year) {
      return selectedPeriodeFilters.includes(form.year);
    }
    return selectedPeriodeFilters.includes(empty);
  };

const filterContext =
  (selectedContextFilters: string[]) =>
  (form: FormForAdminForm): boolean => {
    if (!selectedContextFilters.length) return true;
    if (form.label) {
      return selectedContextFilters.includes(form.label);
    }
    return selectedContextFilters.includes(empty);
  };

const filterSubmitted =
  (selectedSubmittedFilters: string[]) =>
  (form: FormForAdminForm): boolean => {
    if (!selectedSubmittedFilters.length) return true;
    return selectedSubmittedFilters.includes(String(form.submitted));
  };

const filterIgnored =
  (selectedIgnoredFilters: string[]) =>
    (form: FormForAdminForm): boolean => {
      if (!selectedIgnoredFilters.length) return true;
      return selectedIgnoredFilters.includes(String(form.isIgnored));
    };

const filterVerstuurdFrom =
  (startDate: Date | undefined | null) =>
  (form: FormForAdminForm): boolean => {
    if (!startDate || startDate === null) return true;
    return startDate <= parse(form.sendOn, "dd/MM/yyyy", new Date());
  };

const filterVerstuurdUntil =
  (endDate: Date | undefined | null) =>
  (form: FormForAdminForm): boolean => {
    if (!endDate || endDate === null) return true;
    return parse(form.sendOn, "dd/MM/yyyy", new Date()) <= endDate;
  };

export function EvaluatiesOverviewFilter({ evaluations, setFilteredEvaluations }: EvaluatiesOverviewFilterProps) {
  const [selectedEmployeeFilters, setSelectedEmployeeFilters] = useState<string[]>([]);
  const [selectedEvaluatorFilters, setSelectedEvaluatorFilters] = useState<string[]>([]);
  const [selectedTeamFilters, setSelectedTeamFilters] = useState<string[]>([]);
  const [selectedPeriodeFilters, setSelectedPeriodeFilters] = useState<string[]>([]);
  const [selectedContextFilters, setSelectedContextFilters] = useState<string[]>([]);
  const [selectedSubmittedFilters, setSelectedSubmittedFilters] = useState<string[]>([]);
  const [selectedIgnoredFilters, setSelectedIgnoredFilters] = useState<string[]>([]);

  const [selectedStartDateFilter, setSelectedStartDateFilter] = useState<Date | undefined | null>();
  const [selectedEndDateFilter, setSelectedEndDateFilter] = useState<Date | undefined | null>();

  const employeeFilters = evaluations
    .map((rr) => rr.assessPersonName)
    .filter((p): p is string => !!p)
    .filter(distinct())
    .map((x) => ({ key: x, value: x }))
    .sort((t1, t2) => (t1.value > t2.value ? 1 : -1));

  const evaluatorFilters = evaluations
    .map((rr) => rr.evaluatorName)
    .filter((p): p is string => !!p)
    .filter(distinct())
    .map((x) => ({ key: x, value: x }))
    .sort((t1, t2) => (t1.value > t2.value ? 1 : -1));

  const teamFilters = evaluations
    .map((rr) => rr.teamName)
    .filter((p): p is string => !!p)
    .filter(distinct())
    .map((x) => ({ key: x, value: x }))
    .sort((t1, t2) => (t1.value > t2.value ? 1 : -1));

  const periodeFilters = evaluations
    .map((rr) => rr.year)
    .filter((p): p is string => !!p)
    .filter(distinct())
    .map((x) => ({ key: x, value: x }));

  const contextFilters = evaluations
    .map((rr) => rr.label)
    .filter((p): p is string => !!p)
    .filter(distinct())
    .map((x) => ({ key: x, value: x }))
    .sort((t1, t2) => (t1.value > t2.value ? 1 : -1));
  contextFilters.push({ key: empty, value: "(Geen)" });

  const submittedFilters = [
    { key: "true", value: "Ja" },
    { key: "false", value: "Nee" },
  ];

  const ignoredFilters = [
    { key: "true", value: "Ja" },
    { key: "false", value: "Nee" },
  ];

  useEffect(
    () =>
      setFilteredEvaluations(
        evaluations
          .filter(filterEmployee(selectedEmployeeFilters))
          .filter(filterEvaluator(selectedEvaluatorFilters))
          .filter(filterTeam(selectedTeamFilters))
          .filter(filterPeriode(selectedPeriodeFilters))
          .filter(filterContext(selectedContextFilters))
          .filter(filterSubmitted(selectedSubmittedFilters))
          .filter(filterIgnored(selectedIgnoredFilters))
          .filter(filterVerstuurdFrom(selectedStartDateFilter))
          .filter(filterVerstuurdUntil(selectedEndDateFilter))
      ),
    [
      evaluations,
      selectedContextFilters,
      selectedEmployeeFilters,
      selectedEndDateFilter,
      selectedEvaluatorFilters,
      selectedPeriodeFilters,
      selectedStartDateFilter,
      selectedSubmittedFilters,
      selectedIgnoredFilters,
      selectedTeamFilters,
      setFilteredEvaluations,
    ]
  );

  const removeFilters = () => {
    setSelectedEmployeeFilters([]);
    setSelectedEvaluatorFilters([]);
    setSelectedTeamFilters([]);
    setSelectedPeriodeFilters([]);
    setSelectedContextFilters([]);
    setSelectedSubmittedFilters([]);
    setSelectedIgnoredFilters([]);
    setSelectedStartDateFilter(null);
    setSelectedEndDateFilter(null);
  };

  const handleDateRangeChanged = (startDate: Date | undefined, endDate: Date | undefined) => {
    setSelectedStartDateFilter(startDate);
    setSelectedEndDateFilter(endDate);
  };

  return (
    <>
      {evaluations.length > 0 && (
        <Grid container>
          <Grid item lg={12} xl={6} style={{ paddingRight: "8px", paddingBottom: "8px" }}>
            <Stack direction={"row"} spacing={1}>
              {employeeFilters.length > 0 && (
                <MultiSelect name="Medewerker" selectedItems={selectedEmployeeFilters} setSelectedItems={setSelectedEmployeeFilters} allItems={employeeFilters} />
              )}
              {evaluatorFilters.length > 0 && (
                <MultiSelect name="Evaluator" selectedItems={selectedEvaluatorFilters} setSelectedItems={setSelectedEvaluatorFilters} allItems={evaluatorFilters} />
              )}
              {teamFilters.length > 0 && <MultiSelect name="Team" selectedItems={selectedTeamFilters} setSelectedItems={setSelectedTeamFilters} allItems={teamFilters} />}

              <DateRangePicker startDate={selectedStartDateFilter} endDate={selectedEndDateFilter} onChange={handleDateRangeChanged} />
            </Stack>
          </Grid>
          <Grid item lg={12} xl={6}>
            <Stack direction={"row"} spacing={1}>
              {periodeFilters.length > 0 && (
                <MultiSelect name="Periode" selectedItems={selectedPeriodeFilters} setSelectedItems={setSelectedPeriodeFilters} allItems={periodeFilters} width={200} />
              )}
              {contextFilters.length > 0 && (
                <MultiSelect name="Context" selectedItems={selectedContextFilters} setSelectedItems={setSelectedContextFilters} allItems={contextFilters} />
              )}
              {submittedFilters.length > 0 && (
                <MultiSelect name="Ingediend" selectedItems={selectedSubmittedFilters} setSelectedItems={setSelectedSubmittedFilters} allItems={submittedFilters} width={150} />
              )}
              {ignoredFilters.length > 0 && (
                <MultiSelect name="Negeren" selectedItems={selectedIgnoredFilters} setSelectedItems={setSelectedIgnoredFilters} allItems={ignoredFilters} width={150} />
              )}
              <Button color="secondary" variant="outlined" onClick={removeFilters}>
                Reset filters
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
}
