import { styled } from "@mui/styles";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Slider, Typography } from "@mui/material";
import styles from "./SliderQuestion.module.scss";
import React, { useState } from "react";

type SliderQuestionProps = {
  title: string;
  marks: { value: number; label: string }[];
  selectedValue: number | null;
  isNvt: boolean | null;
  isDisabled?: boolean;
  valueChanged?: (value: number, isNvt: boolean) => void;
};

const VerticalSlider = styled(Slider)(() => ({
  orientation: "vertical",
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 3,
    width: 16,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

export function SliderQuestion({ marks, selectedValue, title, isNvt, isDisabled, valueChanged }: SliderQuestionProps) {
  const [sliderValue, setSliderValue] = useState<number | null>(selectedValue);

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  };
  const handleSubmitSliderChange = (_: Event | React.SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    valueChanged && valueChanged(newValue as number, false);
  };

  const handleIsNvtChange = (checked: boolean) => {
    setSliderValue(null);
    valueChanged && valueChanged(0, checked);
  };

  return (
    <Accordion expanded={true}>
      <AccordionSummary aria-controls={title} id={title} className={selectedValue === null && isNvt === null ? styles.questionUnanswered : styles.questionAnswered}>
        <Typography className={styles.title}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box height={`${marks.length * 40}px`} style={{ paddingBottom: "30px", paddingTop: "10px" }}>
          <VerticalSlider
            orientation={"vertical"}
            aria-label="Gelieve een waarde te selecteren"
            value={sliderValue || 0}
            marks={marks}
            valueLabelDisplay="on"
            onChange={handleSliderChange}
            onChangeCommitted={handleSubmitSliderChange}
            disabled={(isDisabled || isNvt) ?? undefined}
          />
        </Box>
        <FormControlLabel
          control={<Checkbox indeterminate={isNvt === null} checked={isNvt === true} disabled={isDisabled} onChange={(e) => handleIsNvtChange(e.target.checked)} />}
          label="Niet van toepassing."
          sx={{ pb: "10px" }}
        />
      </AccordionDetails>
    </Accordion>
  );
}
