import Breadcrumb from "../../components/Breadcrumb";
import { Accordion, AccordionSummary, Grid, Stack, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiClient, useApi } from "../../api/apiClient";
import { useQuery } from "react-query";
import { GridItemLoadingAndDataChecker } from "../../components/GridItemLoadingAndDataChecker";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useState } from "react";
import styles from "./ScoreOverview.module.scss";
import { RouteComponentProps } from "react-router";
import { ScoreDetail } from "./ScoreDetail";

function getTeamContexts(teamKey: string) {
  return function () {
    return apiClient["/teams/{teamKey}/contexts"].get({
      path: {
        teamKey: teamKey,
      },
    });
  };
}

type ScoreOverviewProps = RouteComponentProps<{
  aggregateKey: string;
}>;

export function ScoreOverview(props: ScoreOverviewProps) {
  const { aggregateKey: teamKey } = props.match.params;
  const [expanded, setExpanded] = useState<string[]>([]);

  const { data: contextData, isLoading: contextsAreLoading } = useQuery(["Team/contexts"], useApi("Context ophalen", getTeamContexts(teamKey)));

  const handleSetExpanded = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? [...expanded, panel] : expanded.filter((e: string) => e !== panel));
  };

  if (contextsAreLoading || !contextData)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumb
          path={[
            { name: "Home", route: "/" },
            { name: "Mijn team", route: `/teambeheerder/mijnteam/${teamKey}` },
          ]}
          currentPage="Scores"
        />
      </Grid>
      <Grid item xs={12}>
        <h1>Scores</h1>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {contextData.formContexts.map((context) => (
            <Grid item xs={12} sx={{ mb: "1rem" }} key={context.id}>
              <Accordion expanded={expanded.indexOf(context.id) !== -1} onChange={handleSetExpanded(context.id)} className={styles.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={context.id} id={context.id} className={styles.accordionHeader}>
                  <Typography variant="subtitle2" className={styles.header}>
                    {context.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12}>
                      <ScoreDetail teamKey={teamKey} contextId={context.id} />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <GridItemLoadingAndDataChecker isLoading={contextsAreLoading} hasData={contextData.formContexts.length > 0} />
    </Grid>
  );
}
