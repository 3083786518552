import Breadcrumb from "../../components/Breadcrumb";
import { Accordion, AccordionSummary, Grid, Stack, AccordionDetails, Typography, Tooltip, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiClient, useApi } from "../../api/apiClient";
import { useQuery } from "react-query";
import { GridItemLoadingAndDataChecker } from "../../components/GridItemLoadingAndDataChecker";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useState } from "react";
import styles from "./EvaluationsIFilledIn.module.scss";
import CheckIcon from "@mui/icons-material/Check";

function getTheFormsIFilledIn() {
  return function () {
    return apiClient["/user/myforms/filledin"].get({});
  };
}

export function EvaluationsIFilledIn() {
  const [expanded, setExpanded] = useState<string[]>([]);

  const { data: formData, isLoading: formsAreLoading } = useQuery(["forms/IFilledIn"], useApi("Evaluaties ophalen", getTheFormsIFilledIn()));

  const handleSetExpanded = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? [...expanded, panel] : expanded.filter((e: string) => e !== panel));
  };

  if (formsAreLoading || !formData)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumb path={[{ name: "Home", route: "/" }]} currentPage="Evaluaties die ik ingevuld heb" />
      </Grid>
      <Grid item xs={12}>
        <h1>Evaluaties die ik ingevuld heb</h1>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {formData.periodes.map((periode) => (
            <Grid item xs={4} sx={{ mb: "1rem" }} key={periode.contextId}>
              <Accordion expanded={expanded.indexOf(periode.contextId) !== -1} onChange={handleSetExpanded(periode.contextId)} className={`${styles.accordion} ${styles.valid}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={periode.contextId} id={periode.contextId} className={styles.accordionHeader}>
                  <Typography variant="subtitle2" className={styles.header}>
                    {periode.label && periode.label}
                    {!periode.label && `Evaluaties ${periode.year}`}
                  </Typography>
                  <Tooltip title="Verzonden op">
                    <Typography variant="subtitle2">{periode.sendOn}</Typography>
                  </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    {periode.forms.map((form) => (
                      <Grid item xs={12} className={styles.personName} key={`${form.evaluatorAccessKey}${form.assessedPersonName}`}>
                        <CheckIcon className={styles.checkIcon} />
                        <Link to={`/form/${form.evaluatorAccessKey}`} target="_blank" className={styles.linkButton}>
                          <Button variant="text">{form.assessedPersonName}</Button>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <GridItemLoadingAndDataChecker isLoading={formsAreLoading} hasData={formData.periodes.length > 0} />
    </Grid>
  );
}
