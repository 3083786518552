import { nlBE } from "date-fns/locale";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { Stack, TextField } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

interface DateRangePickerProps {
  startDate: Date | undefined | null;
  endDate: Date | undefined | null;
  onChange: (startDate: Date | undefined, endDate: Date | undefined) => void;
}

export function DateRangePicker({ startDate, endDate, onChange }: DateRangePickerProps) {
  const [open, setOpen] = useState(false);
  const [timesClicked, setTimesClicked] = useState(0);
  const [startDateState, setStartDateState] = useState<Date | undefined>(startDate ?? undefined);
  const [endDateState, setEndDateState] = useState<Date | undefined>(endDate ?? undefined);
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    if (startDate === null && endDate === null) {
      setReset(true);
      setStartDateState(undefined);
      setEndDateState(undefined);
      onChange(undefined, undefined);
    }
  }, [endDate, endDateState, onChange, startDate, startDateState]);

  const handleOnChange = (ranges: any) => {
    setTimesClicked(timesClicked + 1);

    const { selection } = ranges;
    setStartDateState(selection.startDate);
    setEndDateState(selection.endDate);
  };

  useEffect(() => {
    if (reset) {
      if (!startDateState && !endDateState) {
        onChange(startDateState, endDateState);
        setReset(false);
      }
    } else {
      if (open && (timesClicked % 2 === 0 || startDateState !== endDateState) && timesClicked !== 0) {
        setTimesClicked(0);
        setOpen(false);
        onChange(startDateState, endDateState);
      }
      if (!open && (startDateState || endDateState)) {
        onChange(startDateState, endDateState);
      }
    }
  }, [endDateState, onChange, open, reset, startDateState, timesClicked]);

  return (
    <Stack direction="row" spacing={1} style={{ position: "relative" }}>
      <DesktopDatePicker
        label="Verstuurd Vanaf"
        value={startDateState ?? null}
        onChange={(newValue) => {
          if (!isNaN(Date.parse(newValue?.toString() ?? ""))) {
            setStartDateState(newValue!);
          }
        }}
        inputFormat="dd/MM/yyyy"
        showToolbar={false}
        renderInput={(params) => <TextField {...params} />}
        open={false}
        onOpen={() => setOpen(!open)}
      />
      <DesktopDatePicker
        label="Verstuurd Tot"
        value={endDateState ?? null}
        onChange={(newValue) => {
          if (!isNaN(Date.parse(newValue?.toString() ?? ""))) {
            setEndDateState(newValue!);
          }
        }}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => <TextField {...params} />}
        open={false}
        onOpen={() => setOpen(!open)}
      />
      {open && (
        <div style={{ position: "absolute", top: "4rem", zIndex: 99 }}>
          <DateRange
            editableDateInputs={false}
            locale={nlBE}
            showDateDisplay={false}
            onChange={handleOnChange}
            moveRangeOnFirstSelection={false}
            ranges={[{ startDate: startDateState, endDate: endDateState, key: "selection" }]}
          />
        </div>
      )}
    </Stack>
  );
}
