import IgnoreIcon from "@mui/icons-material/ThumbDown";
import AcceptIcon from "@mui/icons-material/ThumbUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CSSProperties } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { RouteComponentProps } from "react-router";
import { apiClient, useApi } from "../../../api/apiClient";
import { GetRoleResponsibilityDiffResponse, RoleResponsibilityWithDiff } from "../../../api/types";
import Breadcrumb from "../../../components/Breadcrumb";
import Colours from "../../../components/Colours";
import { GridItemLoadingAndDataChecker } from "../../../components/GridItemLoadingAndDataChecker";
import styles from "./SyncRole.module.scss";

type SyncRoleProps = RouteComponentProps<{
  id: string;
}>;

async function getDiffForRole(blueprintRoleId: string) {
  return await apiClient["/blueprint/diff"].get({
    query: { BlueprintRoleId: blueprintRoleId },
  });
}

async function postIgnoreItem(blueprintId: string) {
  return await apiClient["/blueprint/ignoreRoleResponsibility"].post({
    query: { blueprintId },
  });
}

async function postUnIgnoreItem(blueprintId: string) {
  return await apiClient["/blueprint/unIgnoreRoleResponsibility"].post({
    query: { blueprintId },
  });
}

async function postAcceptItem(blueprintId: string) {
  return await apiClient["/blueprint/syncRoleResponsibility"].post({
    query: { BlueprintId: blueprintId },
  });
}

export function SyncRole(props: SyncRoleProps) {
  const queryClient = useQueryClient();
  const { id } = props.match.params;

  const { data, isLoading } = useQuery(
    ["getDiffForRole", id],
    useApi("", () => getDiffForRole(id))
  );

  const { mutate: ignoreItem } = useMutation(useApi("Rolverantwoordelijkheid negeren", postIgnoreItem), {
    onMutate: () => queryClient.cancelQueries("getDiffForRole"),
    onSettled: () => queryClient.invalidateQueries("getDiffForRole"),
  });

  const { mutate: acceptItem } = useMutation(useApi("Rolverantwoordelijkheid herstellen", postAcceptItem), {
    onMutate: () => queryClient.cancelQueries("getDiffForRole"),
    onSettled: () => queryClient.invalidateQueries("getDiffForRole"),
  });

  const { mutateAsync: unIgnoreItemAsync } = useMutation(useApi("Rolverantwoordelijkheid accepteren", postUnIgnoreItem), {
    onMutate: () => queryClient.cancelQueries("getDiffForRole"),
    onSettled: () => queryClient.invalidateQueries("getDiffForRole"),
  });

  const diffs: RoleResponsibilityWithDiff[] = (data as GetRoleResponsibilityDiffResponse)?.diffs ?? [];

  async function acceptIgnoredItemClickHandler(blueprintId: string) {
    const unIgnoreResult = unIgnoreItemAsync(blueprintId);

    unIgnoreResult.then(() => {
      acceptItem(blueprintId);
    });
  }

  function getRowStyle(data: RoleResponsibilityWithDiff): CSSProperties {
    if (data.status === "Ignored") {
      return {
        backgroundColor: Colours.rowBackgroundColourDisapproved,
        color: Colours.rowTextColourDisapproved,
      };
    } else {
      return {
        backgroundColor: Colours.rowBackgroundColour,
      };
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb
            path={[
              { name: "Home", route: "/" },
              { name: "Rollen beheer", route: "/guildmaster/rollen" },
            ]}
            currentPage={`Synchroniseer ${data?.roleName}-Rol`}
          />
        </Grid>
        <Grid item xs={12}>
          <h1>Synchroniseer {data?.roleName}-Rol</h1>
        </Grid>
        <Grid item xs={12}>
          <h2>Te synchroniseren rolverantwoordelijkheden</h2>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  <TableCell className={styles.fase}>Fase</TableCell>
                  <TableCell className={styles.naam}>Naam</TableCell>
                  <TableCell className={styles.status}>Status</TableCell>
                  <TableCell className={styles.icon} />
                  <TableCell className={styles.icon} />
                </TableRow>
              </TableHead>
              <TableBody>
                {diffs.map((diff) => (
                  <TableRow key={diff.blueprintId} className={styles.tableRow} style={getRowStyle(diff)}>
                    <TableCell className={styles.fase} style={getRowStyle(diff)}>
                      {diff.phaseName}
                    </TableCell>
                    <TableCell className={styles.naam} style={getRowStyle(diff)}>
                      {diff.name}
                    </TableCell>
                    <TableCell className={styles.status} style={getRowStyle(diff)}>
                      {getStatus(diff)}
                    </TableCell>
                    <TableCell className={styles.icon}>
                      {diff.status !== "Ignored" ? (
                        <AcceptIcon
                          titleAccess="wijziging accepteren"
                          className={styles.clickableGreen}
                          onClick={() =>
                            (async function (blueprintId: string) {
                              acceptItem(blueprintId);
                            })(diff.blueprintId ?? "")
                          }
                        />
                      ) : (
                        <DeleteIcon
                          titleAccess="verwijdering uitvoeren"
                          className={styles.clickableGreenWhite}
                          onClick={() => acceptIgnoredItemClickHandler(diff.blueprintId ?? "")}
                        />
                      )}
                    </TableCell>
                    <TableCell className={styles.icon}>
                      {diff.status !== "Ignored" && (
                        <IgnoreIcon
                          titleAccess="wijziging niet verwerken"
                          className={styles.clickableRed}
                          onClick={() =>
                            (async function (blueprintId: string) {
                              ignoreItem(blueprintId);
                            })(diff.blueprintId ?? "")
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <GridItemLoadingAndDataChecker isLoading={isLoading} hasData={diffs.length > 0} />
      </Grid>
    </>
  );
}

function getStatus(data: RoleResponsibilityWithDiff) {
  switch (data.status) {
    case "New":
      return "Nieuw";
    case "Deleted":
      return "Komt niet meer voor";
    case "Modified":
      return "Aangepast";
    case "Ignored":
      return "Komt niet meer voor";
    default:
      return <></>;
  }
}
