import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

type ConfirmAdminSaveFormDialogProps = {
  open: boolean;
  handleClose: (isCancelled: boolean) => void;
};

export function ConfirmAdminSaveFormDialog({ open, handleClose }: ConfirmAdminSaveFormDialogProps) {
  return (
    <Dialog open={open} onClose={() => handleClose(true)}>
      <DialogTitle>Evaluatie opslaan</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            Ben je zeker dat je deze evaluatie wil opslaan?
            <br />
            Deze actie kan niet ongedaan worden gemaakt.
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => handleClose(true)}>
          Nee
        </Button>
        <Button variant="contained" onClick={() => handleClose(false)}>
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
}
