import { Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import styles from "./AddTeamMemberRolesDialog.module.scss";
import { MultiSelect } from "../../components/MultiSelect";
import { useState } from "react";
import { useGetRoles } from "../../hooks/useGetRoles";

type AddTeamMemberRolesDialogProps = {
  open: boolean;
  selectedMemberRoles: string[];
  handleClose: (isCanceled: boolean) => void;
  setNewSelectedMemberRoles: (roleIds: string[]) => void;
};

export function AddTeamMemberRolesDialog({ open, handleClose, selectedMemberRoles, setNewSelectedMemberRoles }: AddTeamMemberRolesDialogProps) {
  const { data } = useGetRoles();
  const rolesNotYetChosen = data?.roles.filter((role) => selectedMemberRoles?.findIndex((r) => r === role.blueprintRoleId) === -1);
  const rolesToDisplay = rolesNotYetChosen?.map((p) => ({ key: p.blueprintRoleId!, value: p.name! })) ?? [];
  const [roleIdsToAdd, setRoleIdsToAdd] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  const updateRolesToAdd = (roleIds: string[]) => {
    if (roleIds.length === 0) {
      setErrorMessage("Gelieve minstens 1 nieuwe rol te selecteren.");
    } else {
      setErrorMessage(undefined);
    }
    setRoleIdsToAdd(roleIds);
  };

  const closeModal = (isCancelled: boolean) => () => {
    if (!isCancelled && roleIdsToAdd) {
      setNewSelectedMemberRoles(roleIdsToAdd);
    }
    setRoleIdsToAdd([]);
    setErrorMessage(undefined);
    handleClose(isCancelled);
  };

  return (
    <>
      <Dialog open={open} onClose={closeModal} className={styles.dialog}>
        <DialogTitle>Rollen toevoegen</DialogTitle>
        <DialogContent>
          <Stack alignSelf="center" spacing={2} paddingTop={2} paddingBottom={2} direction={"row"}>
            <MultiSelect allItems={rolesToDisplay} name="Rollen" selectedItems={roleIdsToAdd} setSelectedItems={updateRolesToAdd} errorMessage={errorMessage} />
            <Stack alignSelf="end" spacing={2} paddingTop={2} paddingBottom={2} direction={"row"}>
              <Button variant="contained" color="secondary" onClick={closeModal(true)}>
                Annuleren
              </Button>
              <Button variant="contained" disabled={roleIdsToAdd.length === 0} onClick={closeModal(false)}>
                Bewaren
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
