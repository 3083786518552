import { TableContainer, Paper, TableBody, TableRow, TableCell, Table, Button } from "@mui/material";
import { parse } from "date-fns";
import { useState } from "react";
import { SortableTableHeader } from "./SortableTableHeader";

export interface HeaderCell<T> {
  id: keyof T;
  label: string;
  render?: (row: T) => React.ReactNode;
  disableSorting?: boolean;
  isDate?: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if ((b[orderBy] === null || b[orderBy] === undefined) && a[orderBy] !== null) return 1;
  if (b[orderBy] !== null && (a[orderBy] === null || a[orderBy] === undefined)) return -1;
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descendingComparatorForDates<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] === null && a[orderBy] !== null) return 1;
  if (b[orderBy] !== null && a[orderBy] === null) return -1;
  if (typeof b[orderBy] === "string" && typeof a[orderBy] === "string") {
    if (parse(String(b[orderBy]), "dd/MM/yyyy", new Date()) < parse(String(a[orderBy]), "dd/MM/yyyy", new Date())) {
      return -1;
    }
    if (parse(String(b[orderBy]), "dd/MM/yyyy", new Date()) > parse(String(a[orderBy]), "dd/MM/yyyy", new Date())) {
      return 1;
    }
  }
  return 0;
}

export type Order = "asc" | "desc";

function getComparator<T>(order: Order, orderBy: keyof T | undefined, headers: HeaderCell<T>[]): (a: T, b: T) => number {
  if (orderBy === undefined) return (a, b) => 0;
  const headerCell = headers.filter((h) => h.id === orderBy);
  if (headerCell.length > 0 && headerCell[0].isDate)
    return order === "desc" ? (a, b) => descendingComparatorForDates(a, b, orderBy) : (a, b) => -descendingComparatorForDates(a, b, orderBy);
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

class SortableTableProps<T> {
  rows: T[] = [];
  headers: HeaderCell<T>[] = [];
  defaultSortedOn?: keyof T;
  defaultSortedDesc: boolean = false;
  detailAction?: (row: T) => void;
}

export function SortableTable<T>({ rows, headers, defaultSortedOn, defaultSortedDesc, detailAction }: SortableTableProps<T>) {
  const [order, setOrder] = useState<Order>(defaultSortedDesc ? "desc" : "asc");
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(defaultSortedOn);

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <SortableTableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} headers={headers} hasDetailButton={!!detailAction} />
        <TableBody>
          {stableSort<T>(rows, getComparator(order, orderBy, headers)).map((row, index) => {
            return (
              <TableRow hover tabIndex={-1} key={`tablerow${row[headers[0].id]}${index}`} className="tableRow">
                {headers.map((header) => (
                  <TableCell key={`tablerowcell${index}${header.label}`}>{header.render ? header.render(row) : row[header.id]}</TableCell>
                ))}
                {detailAction && (
                  <TableCell key={"tablerowcellDetail"} align="right" width="10rem">
                    <Button variant="contained" onClick={() => detailAction(row)}>
                      Detail
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
