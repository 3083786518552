import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Button, FormControlLabel, FormGroup, Grid, IconButton, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiClient, useApi } from "../../api/apiClient";
import { PostParameters } from "../../api/types";
import Breadcrumb from "../../components/Breadcrumb";
import { GridItemLoadingAndDataChecker } from "../../components/GridItemLoadingAndDataChecker";
import styles from "./Teambeheer.module.scss";
import { TeamDialog } from "./TeamDialog";

async function postTeam({ body }: PostParameters<"/teams">) {
  return await apiClient["/teams"].post({
    body: {
      type: "application/json",
      value: body,
    },
  });
}

const activateTeam = ({ path }: PostParameters<"/teams/{id}/activate">) => {
  return apiClient["/teams/{id}/activate"].post({ path: path });
};

const deactivateTeam = ({ path }: PostParameters<"/teams/{id}/deactivate">) => {
  return apiClient["/teams/{id}/deactivate"].post({ path: path });
};

const getTeams = () => {
  return apiClient["/teams"].get({});
};

const initialState = { teamId: "", teamName: "", teamAdmins: [] };

export function Teambeheer() {
  const queryClient = useQueryClient();

  const [openDialog, setOpenDialog] = useState(false);

  const [valuesToSave, setValuesToSave] = useState<{ teamId: string; teamName: string; teamAdmins: string[] }>(initialState);
  const [showInactive, setShowInactive] = useState(false);

  const { data, isLoading } = useQuery(["Teams"], useApi("Teams ophalen", getTeams));

  const handleAddNew = () => {
    setOpenDialog(true);
  };

  const { mutate: saveTeam } = useMutation(useApi("Team opslaan", postTeam), {
    onMutate: () => queryClient.cancelQueries("Teams"),
    onSettled: () => queryClient.invalidateQueries("Teams"),
  });

  const { mutate: setTeamActive } = useMutation(useApi("Team activeren", activateTeam), {
    onMutate: () => queryClient.cancelQueries("Teams"),
    onSettled: () => queryClient.invalidateQueries("Teams"),
  });

  const { mutate: setTeamDeactive } = useMutation(useApi("Team deactiveren", deactivateTeam), {
    onMutate: () => queryClient.cancelQueries("Teams"),
    onSettled: () => queryClient.invalidateQueries("Teams"),
  });

  const handleCloseDialog = (isCanceled: boolean) => () => {
    if (!isCanceled) {
      saveTeam({
        body: {
          teamId: valuesToSave.teamId || null,
          teamName: valuesToSave.teamName,
          personeeltoolIdTeamAdmins: valuesToSave.teamAdmins,
        },
      });
    }
    setValuesToSave(initialState);
    setOpenDialog(false);
  };

  const handleEdit = (teamId: string, name: string, teamAdmin: string[]) => {
    setValuesToSave({ teamId: teamId, teamName: name, teamAdmins: teamAdmin });
    setOpenDialog(true);
  };

  const handleStatusChange = (teamId: string, isActive: boolean) => {
    if (isActive) {
      return setTeamActive({ path: { id: teamId } });
    } else {
      setTeamDeactive({ path: { id: teamId } });
    }
  };

  const teams = data?.teams ?? [];
  const filteredTeams = showInactive ? teams : teams.filter((t) => t.isActive);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb path={[{ name: "Home", route: "/" }]} currentPage="Team beheer" />
        </Grid>
        <Grid item xs={12}>
          <h1>Team beheer</h1>
        </Grid>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <h2>Teams</h2>
          <Stack direction="row" spacing={5}>
            <FormGroup>
              <FormControlLabel
                labelPlacement="start"
                label="Toon inactieve teams"
                control={<Switch color="secondary" checked={showInactive} onChange={() => setShowInactive(!showInactive)} />}
              />
            </FormGroup>
            <Button variant="contained" onClick={handleAddNew} endIcon={<AddIcon />}>
              Toevoegen
            </Button>
          </Stack>
          <TeamDialog open={openDialog} handleClose={handleCloseDialog} setValuesToSave={setValuesToSave} valuesToSave={valuesToSave} />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  <TableCell>Naam</TableCell>
                  <TableCell>Teambeheerders</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTeams.map((t) => (
                  <TableRow key={t.aggregateKey} className={styles.tableRow}>
                    <TableCell>{t.name}</TableCell>
                    <TableCell>{t.teamAdmins!.map((a) => a.name).join(", ")}</TableCell>
                    <TableCell className={styles.smallCell}>{t.isActive ? "Actief" : "Inactief"}</TableCell>
                    <TableCell>
                      <Stack direction={"row"} justifyContent={"flex-end"}>
                        <>
                          <Switch checked={t.isActive} onChange={() => handleStatusChange(t.aggregateKey!, !t.isActive)} />
                          &nbsp;
                          <IconButton
                            onClick={() =>
                              handleEdit(
                                t.aggregateKey!,
                                t.name!,
                                t.teamAdmins!.map((a) => a.key!)
                              )
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <GridItemLoadingAndDataChecker isLoading={isLoading} hasData={filteredTeams.length > 0} />
      </Grid>
    </>
  );
}
