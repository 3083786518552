import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Alert, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, RouteComponentProps, Switch } from "react-router-dom";
import "./App.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { MessagePopup } from "./components/MessagePopup";
import { PageLayout } from "./components/PageLayout";
import { SnackBarContextProvider } from "./components/SnackbarContext";
import { ApplicationRole, UserContextProvider, useUser } from "./components/UserContext";
import { Rechtenbeheer } from "./pages/admin/Rechtenbeheer";
import { EvaluatiesOverview as AdminEvaluatiesOverview } from "./pages/admin/EvaluatiesOverview";
import { ScoresOverview as AdminScoresOverview } from "./pages/admin/ScoresOverview";
import { ScoreDetail } from "./pages/admin/ScoreDetail";
import { MyEvaluations } from "./pages/medewerker/MyEvaluations";
import { EvaluationsToFillIn } from "./pages/medewerker/EvaluationsToFillIn";
import { EvaluationsIFilledIn } from "./pages/medewerker/EvaluationsIFilledIn";
import { ScoreOverview as MyScores } from "./pages/medewerker/ScoreOverview";
import { EditForm } from "./pages/form/EditForm";
import { TeamDetail } from "./pages/teambeheerder/TeamDetail";
import { RoleDistribution } from "./pages/teambeheerder/RoleDistribution";
import { UserDetail } from "./pages/teambeheerder/UserDetail";
import { EvaluatiesOverview } from "./pages/teambeheerder/EvaluatiesOverview";
import { ScoreOverview as TeambeheerderScoreOverview } from "./pages/teambeheerder/ScoreOverview";
import { Rollenbeheer } from "./pages/guildmaster/rollenbeheer/Rollenbeheer";
import { Teambeheer } from "./pages/admin/Teambeheer";
import { EditQuestion } from "./pages/guildmaster/questiondetail/EditQuestion";
import { NewQuestion } from "./pages/guildmaster/questiondetail/NewQuestion";
import { RoleDetail } from "./pages/guildmaster/roledetail/RoleDetail";
import { SyncRole } from "./pages/guildmaster/rollenbeheer/SyncRole";
import { Home } from "./pages/Home";
import { Profile } from "./pages/Profile";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

export type RoutePath =
  | "/profile"
  | "/medewerker/placeholder"
  | `/teambeheerder/mijnteam/${string}`
  | "/guildmaster/rollen"
  | `/guildmaster/rollen/${string}/sync`
  | `/guildmaster/rollen/${string}#${string}`
  | `/guildmaster/rolverantwoordelijkheden/${string}/questions/new`
  | `/guildmaster/rolverantwoordelijkheden/${string}/questions/${string}`
  | "/admin/teambeheer"
  | "/admin/rechtenbeheer"
  | "/admin/evaluaties"
  | "/admin/scores"
  | `/admin/scores/${string}/details/${string}`
  | "/";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter>
          <SnackBarContextProvider>
            <UserContextProvider>
              <Switch>
                {/* line below is en example for routes where authentication doesn't matter */}
                <Route path="/form/:accessKey">
                  <EditForm />
                  <MessagePopup />
                </Route>
                <Route path="/">
                  <PageLayout>
                    <Grid container justifyContent="center">
                      <div
                        style={{
                          width: "100%",
                          marginLeft: "4em",
                          marginRight: "4em",
                          marginBottom: "3em",
                        }}
                      >
                        <AuthenticationSensitiveRoutes />
                      </div>
                    </Grid>
                    <MessagePopup />
                  </PageLayout>
                </Route>
              </Switch>
            </UserContextProvider>
          </SnackBarContextProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

function AuthenticationSensitiveRoutes() {
  return (
    <>
      <AuthenticatedTemplate>
        <Switch>
          <Route path="/profile" component={Profile} />
          <PrivateRoute allowedRoles={[ApplicationRole.Medewerker]} path="/medewerker/myevaluations" component={MyEvaluations} />
          <PrivateRoute allowedRoles={[ApplicationRole.Medewerker]} path="/medewerker/evaluationstofillin" component={EvaluationsToFillIn} />
          <PrivateRoute allowedRoles={[ApplicationRole.Medewerker]} path="/medewerker/evaluationsifilledin" component={EvaluationsIFilledIn} />
          <PrivateRoute allowedRoles={[ApplicationRole.Medewerker]} path="/medewerker/scores" component={MyScores} />
          <Route path="/teambeheerder/mijnteam/:aggregateKey/teamlid/:personeelsToolId" component={UserDetail} />
          <Route path="/teambeheerder/mijnteam/:aggregateKey/rolverdeling" component={RoleDistribution} />
          <Route path="/teambeheerder/mijnteam/:aggregateKey/evaluaties" component={EvaluatiesOverview} />
          <Route path="/teambeheerder/mijnteam/:aggregateKey/scoreoverzicht" component={TeambeheerderScoreOverview} />
          <Route path="/teambeheerder/mijnteam/:aggregateKey" component={TeamDetail} />
          <PrivateRoute allowedRoles={[ApplicationRole.Guildmaster, ApplicationRole.Admin]} path="/guildmaster/rollen/:id/sync" component={SyncRole} />
          <PrivateRoute allowedRoles={[ApplicationRole.Guildmaster, ApplicationRole.Admin]} path="/guildmaster/rollen/:id/:roleresponsibilityId" component={RoleDetail} />
          <PrivateRoute allowedRoles={[ApplicationRole.Guildmaster, ApplicationRole.Admin]} path="/guildmaster/rollen/:id" component={RoleDetail} />
          <PrivateRoute
            allowedRoles={[ApplicationRole.Guildmaster, ApplicationRole.Admin]}
            path="/guildmaster/rolverantwoordelijkheden/:roleresponsibilityId/questions/new"
            component={NewQuestion}
          />
          <PrivateRoute
            allowedRoles={[ApplicationRole.Guildmaster, ApplicationRole.Admin]}
            path="/guildmaster/rolverantwoordelijkheden/:roleresponsibilityId/questions/:questionKey"
            component={EditQuestion}
          />
          <PrivateRoute allowedRoles={[ApplicationRole.Guildmaster, ApplicationRole.Admin]} path="/guildmaster/rollen" component={Rollenbeheer} />
          <PrivateRoute allowedRoles={[ApplicationRole.Admin]} path="/admin/teambeheer" component={Teambeheer} />
          <PrivateRoute allowedRoles={[ApplicationRole.Admin]} path="/admin/rechtenbeheer" component={Rechtenbeheer} />
          <PrivateRoute allowedRoles={[ApplicationRole.Admin]} path="/admin/evaluaties" component={AdminEvaluatiesOverview} />
          <PrivateRoute allowedRoles={[ApplicationRole.Admin]} path="/admin/scores/:context/details/:personeeltoolId" component={ScoreDetail} />
          <PrivateRoute allowedRoles={[ApplicationRole.Admin]} path="/admin/scores" component={AdminScoresOverview} />
          <Route path="/" component={Home} />
        </Switch>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Typography variant="h6" align="center">
          Je bent niet ingelogd. Log in om de applicatie te gebruiken.
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
}

const PrivateRoute = ({ component: Component, path, allowedRoles }: { component: React.FC<RouteComponentProps<any>>; path: string; allowedRoles: Array<ApplicationRole> }) => {
  const { roles } = useUser();

  const isAllowed = allowedRoles.some((r) => roles.includes(r));

  return <Route path={path} render={(props) => (isAllowed ? <Component {...props} /> : <AccessDenied />)} />;
};

const AccessDenied = () => <Alert severity="error">Je hebt geen toegang tot deze pagina.</Alert>;

export default App;
