export const id = <T>(x: T) => x;

export function distinct<T>(getKey: (x: T) => any = id) {
  const keyMap = new Map();

  return function (item: T) {
    const key = getKey(item);
    if (keyMap.has(key)) {
      return false;
    }
    keyMap.set(key, null);
    return true;
  };
}

export const K = <T>(x: T) => () => x;

export function swap<T>(items: T[], index1: number, index2: number) {
  const tmpItems = items.slice();
  tmpItems[index1] = items[index2];
  tmpItems[index2] = items[index1];

  return tmpItems;
}
