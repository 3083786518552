import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Collapse, IconButton, Switch, TableCell, TableRow } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useCallback, useState } from "react";
import { Role, RoleResponsibility } from "../../../api/types";
import { EvaluatorDialog } from "./EvaluatorDialog";
import { Questions } from "./Questions";
import { WeightDialog } from "./WeightDialog";

type RoleDetailRowProps = {
  data: RoleResponsibility;
  isExpanded: boolean;
  onToggle: (blueprintId: string) => void;
  roles: Role[];
  updateEvaluators: (blueprintId: string, evaluatorIds: string[]) => void;
  updateWeight: (blueprintId: string, weight: number) => void;
  updateIsActive: (blueprintId: string, isActive: boolean) => void;
};

export function RoleDetailRow({ data, isExpanded, onToggle, roles, updateEvaluators, updateWeight, updateIsActive }: RoleDetailRowProps) {
  const [evaluatorDialogOpen, setEvaluatorDialogOpen] = useState(false);
  const [weightDialogOpen, setWeightDialogOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>(data.evaluators?.map((e) => e.blueprintRoleId!) || []);
  const [weight, setWeight] = useState(data.weight || 1);

  const handleEvaluatorClickOpen = () => {
    setEvaluatorDialogOpen(true);
  };

  const handleEvaluatorsClose = (isCanceled: boolean) => () => {
    if (!isCanceled) {
      updateEvaluators(data.blueprintId!, selectedRoles);
    } else {
      setSelectedRoles(data.evaluators?.map((e) => e.blueprintRoleId!) || []);
    }
    setEvaluatorDialogOpen(false);
  };

  const handleWeightClickOpen = () => {
    setWeightDialogOpen(true);
  };

  const handleWeightClose = (newValue: number, isCancelled: boolean) => {
    if (!isCancelled) {
      setWeight(newValue);
      updateWeight(data.blueprintId!, newValue);
    }

    setWeightDialogOpen(false);
  };

  const allRoles = roles.map((r) => ({ key: r.blueprintRoleId!, value: r.name! }));

  return (
    <>
      <TableRow className="tableRow">
        <TableCell className="smallPadding">
          <IconButton aria-label="expand row" size="small" onClick={useCallback(() => onToggle(data.blueprintId!), [data.blueprintId, onToggle])}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{data.phaseName}</TableCell>
        <TableCell>{data.name}</TableCell>
        <TableCell>
          <Button onClick={handleEvaluatorClickOpen} startIcon={<EditIcon />}>
            {data.evaluators?.map((e) => e.name).join(", ")}
          </Button>
          <EvaluatorDialog
            allRoles={allRoles}
            name={data.name!}
            handleClose={handleEvaluatorsClose}
            open={evaluatorDialogOpen}
            selectedRoles={selectedRoles}
            setSelectedRoles={setSelectedRoles}
          />
        </TableCell>
        <TableCell>
          <Button onClick={handleWeightClickOpen} startIcon={<EditIcon />}>
            {weight}
          </Button>
          <WeightDialog name={data.name!} open={weightDialogOpen} handleClose={handleWeightClose} initialValue={weight} />
        </TableCell>
        <TableCell>
          {data.isActive ? "Actief" : "Inactief"}
          <Switch checked={data.isActive} onChange={() => updateIsActive(data.blueprintId!, data.isActive!)} />
        </TableCell>
        <TableCell>
          {data.urlDetailedInfo && (
            <IconButton href={data.urlDetailedInfo} target="_blank">
              <InfoIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>{data.blueprintId && <Questions blueprintRoleResponsibilityId={data.blueprintId} roleResponsibilityName={data.name || ""} />}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
