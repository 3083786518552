import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { useQuery } from "react-query";
import { apiClient, useApi } from "../../api/apiClient";
import styles from "./TeamDialog.module.scss";
import { MultiSelect } from "../../components/MultiSelect";

type CreateNewTeamDialogProps = {
  open: boolean;
  handleClose: (isCanceled: boolean) => () => void;
  setValuesToSave: (setParamsToSave: { teamId: string; teamName: string; teamAdmins: string[] }) => void;
  valuesToSave: { teamId: string; teamName: string; teamAdmins: string[] };
};

const getAllActiveQframers = () => {
  return apiClient["/people"].get({ query: { OnlyActive: true } });
};

export function TeamDialog({ open, handleClose, setValuesToSave, valuesToSave }: CreateNewTeamDialogProps) {
  const { data } = useQuery(["ActiveQframers"], useApi("Qframers ophalen", getAllActiveQframers));
  const allActiveQframers = data?.people?.map((p) => ({ key: p.id!, value: p.name! })) ?? [];

  const onTeamNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setValuesToSave({ ...valuesToSave, teamName: value });
  };

  const setTeamAdmins = (selectedTeamAdmins: string[]) => {
    setValuesToSave({ ...valuesToSave, teamAdmins: selectedTeamAdmins });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} className={styles.dialog}>
        <DialogTitle>Team toevoegen</DialogTitle>
        <DialogContent>
          <Stack alignSelf="center" spacing={2} direction={"row"}>
            <Stack alignSelf="center" spacing={2} paddingTop={2} paddingBottom={2}>
              <TextField variant="outlined" label="Team" fullWidth onChange={onTeamNameChange} value={valuesToSave.teamName} />

              <MultiSelect allItems={allActiveQframers} name="Teambeheerders" selectedItems={valuesToSave.teamAdmins} setSelectedItems={setTeamAdmins} />
            </Stack>
            <Stack alignSelf="end" spacing={2} paddingTop={2} paddingBottom={2} direction={"row"}>
              <Button variant="contained" color="secondary" onClick={handleClose(true)}>
                Annuleren
              </Button>
              <Button variant="contained" onClick={handleClose(false)}>
                Bewaren
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
