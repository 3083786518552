import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { RoleWithResponsibilities, ExternalEvaluator, ExternalEvaluatorFromTeamMemberResponse } from "../../api/types";
import { MultiSelect } from "../../components/MultiSelect";
import styles from "./UpdateExternalEvaluatorDialog.module.scss";

type UpdateExternalEvaluatorDialogProps = {
  open: boolean;
  roles: RoleWithResponsibilities[];
  externalEvaluatorToEdit?: ExternalEvaluatorFromTeamMemberResponse;
  handleClose: () => void;
  handleSave: (externalEvaluatorToAdd: ExternalEvaluator) => void;
};

export function UpdateExternalEvaluatorDialog({ open, roles, externalEvaluatorToEdit, handleClose, handleSave }: UpdateExternalEvaluatorDialogProps) {
  const rolesToDisplay = roles.map((p) => ({ key: p.blueprintRoleId!, value: p.name! })) ?? [];
  const [roleNamesToAdd, setRoleNamesToAdd] = useState<string[]>(
    externalEvaluatorToEdit?.rolesToEvaluate.filter((r) => roles.findIndex((role) => role.blueprintRoleId === r.blueprintRoleId) !== -1).map((r) => r.blueprintRoleId) ?? []
  );
  const [firstName, setFirstName] = useState<string | undefined>(externalEvaluatorToEdit?.firstName);
  const [name, setName] = useState<string | undefined>(externalEvaluatorToEdit?.name);
  const [email, setEmail] = useState<string | undefined>(externalEvaluatorToEdit?.email);
  const [clickedSave, setClickedSave] = useState<boolean>(false);

  const clearInputs = () => {
    setRoleNamesToAdd([]);
    setFirstName(undefined);
    setName(undefined);
    setEmail(undefined);
    setClickedSave(false);
  };

  const closeModal = () => {
    clearInputs();
    handleClose();
  };

  const isValidEmail = (email: string) => {
    const regularExpression =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  };

  const saveExternalEvaluator = () => {
    setClickedSave(true);
    if (firstName && name && email && isValidEmail(email) && roleNamesToAdd.length > 0) {
      handleSave({
        aggregateKey: externalEvaluatorToEdit?.aggregateKey,
        firstName,
        name,
        email,
        isActive: true,
        rolesToEvaluate: roleNamesToAdd,
      });
      clearInputs();
    }
  };

  return (
    <Dialog open={open} onClose={closeModal} className={styles.dialog}>
      <DialogTitle>Externe evaluator toevoegen</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={styles.gridcontainer}>
          <Grid item xs={12} md={6}>
            <TextField
              error={clickedSave && !firstName}
              variant="outlined"
              label="Voornaam"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              helperText={clickedSave && !firstName ? "Gelieve een voornaam in te vullen" : undefined}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={clickedSave && !name}
              variant="outlined"
              label="Naam"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              helperText={clickedSave && !name ? "Gelieve een naam in te vullen" : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={clickedSave && (!email || !isValidEmail(email!))}
              variant="outlined"
              type={email}
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={clickedSave && (!email || !isValidEmail(email!)) ? "Gelieve een geldig e-mailadres in te vullen" : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelect
              allItems={rolesToDisplay}
              name="Rollen"
              selectedItems={roleNamesToAdd}
              setSelectedItems={setRoleNamesToAdd}
              errorMessage={clickedSave && roleNamesToAdd.length === 0 ? "Gelieve minstens 1 nieuwe rol te selecteren." : undefined}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Annuleren
        </Button>
        <Button variant="contained" disabled={false} onClick={saveExternalEvaluator}>
          Bewaren
        </Button>
      </DialogActions>
    </Dialog>
  );
}
