import { Button, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router";
import { apiClient, apiClientForDownloads, useApi } from "../../api/apiClient";
import Breadcrumb from "../../components/Breadcrumb";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ScoreGraph } from "../../components/ScoreGraph";

function getScoreDetails(context: string, personeeltoolId: string) {
  return function () {
    return apiClient["/forms/context/{context}/details/{personeelstoolId}"].get({
      path: {
        context: context,
        personeelstoolId: personeeltoolId,
      },
    });
  };
}

async function downloadContextExcel(context: string, personeelstoolId: string, name: string) {
  return apiClientForDownloads["/forms/context/{context}/details/{personeelstoolId}/excel"]
    .post({
      path: {
        context: context,
        personeelstoolId: personeelstoolId,
      },
    })
    .then((response: any) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.value);
      link.setAttribute("download", `Resultaten_Evaluatie_${context}_${name}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
}

type ScoreDetailProps = RouteComponentProps<{
  context: string;
  personeeltoolId: string;
}>;

type Evaluator = {
  name: string;
  team: string;
  isSelf: boolean;
};

export function ScoreDetail(props: ScoreDetailProps) {
  const { context, personeeltoolId } = props.match.params;
  const [evaluators, setEvaluators] = useState<Evaluator[]>();

  const { data: scoreDetails, isLoading } = useQuery(
    ["ScoreDetails", context, personeeltoolId],
    useApi("Scoredetails voor geevalueerde voor evaluatieronde ophalen", getScoreDetails(context, personeeltoolId))
  );

  useEffect(() => {
    if (!scoreDetails) setEvaluators(undefined);
    var allEvaluators = scoreDetails?.generalComments
      .map((comment) => ({ name: comment.evaluator.evaluatorName, team: comment.evaluator.teamName, isSelf: comment.evaluator.isSelf } as Evaluator))
      .filter((value, index, self) => self.map((s) => `${s.name}-${s.team}`).indexOf(`${value.name}-${value.team}`) === index)
      .sort((n1, n2) => {
        if (n1.isSelf < n2.isSelf) return 1;
        if (n1.isSelf > n2.isSelf) return -1;
        if (n1.name > n2.name) return 1;
        if (n1.name < n2.name) return -1;
        if (n1.team > n2.team) return 1;
        if (n1.team < n2.team) return -1;
        return 0;
      });

    setEvaluators(allEvaluators);
  }, [scoreDetails]);

  if (isLoading || !scoreDetails)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumb
            path={[
              { name: "Home", route: "/" },
              { name: "Scores", route: "/admin/scores" },
            ]}
            currentPage="Score Details"
          />
        </Grid>
        <Grid item xs={12}>
          <h1>
            Score details: {scoreDetails?.name} ({context})
          </h1>
        </Grid>
        {scoreDetails && (
          <>
            <Grid item xs={12}>
              <ScoreGraph scoreDetails={scoreDetails!.scores} />
            </Grid>
            <Grid item xs={12} textAlign="right">
              <Button disabled={!scoreDetails} variant="contained" onClick={() => downloadContextExcel(context, personeeltoolId, scoreDetails!.name)}>
                Download resultaten
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: "800px" }}>
                  <Table stickyHeader>
                    <TableHead className="tableHeader">
                      <TableRow key="header">
                        <TableCell>Rol</TableCell>
                        <TableCell>Rolverantwoordelijkheid</TableCell>
                        <TableCell>Vraag</TableCell>
                        {evaluators?.map((evaluator) => (
                          <Fragment key={`${evaluator.name}-${evaluator.team}-header`}>
                            <TableCell>
                              {evaluators.filter((e) => e.name === evaluator.name).length > 1
                                ? `${evaluator.isSelf ? "Zelfevaluatie" : evaluator.name} (${evaluator.team})`
                                : evaluator.isSelf
                                ? "Zelfevaluatie"
                                : evaluator.name}
                            </TableCell>
                          </Fragment>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scoreDetails?.roles?.map((role) =>
                        role.roleResponsibilities.map((rr) => {
                          return (
                            <Fragment key={`${role.name}-${rr.name}`}>
                              {rr.questions.map((question) => (
                                <TableRow key={`${role.name}-${rr.name}-${question.questionPhrase}`} className="tableRow">
                                  <TableCell>{role.name}</TableCell>
                                  <TableCell>
                                    {rr.name}
                                    {rr.phase && ` (${rr.phase})`}
                                  </TableCell>
                                  <TableCell>{question.questionPhrase}</TableCell>
                                  {evaluators?.map((evaluator) =>
                                    question.scores.filter((score) => score.evaluator.evaluatorName === evaluator.name && score.evaluator.teamName === evaluator.team).length >
                                    0 ? (
                                      question.scores
                                        .filter((score) => score.evaluator.evaluatorName === evaluator.name && score.evaluator.teamName === evaluator.team)
                                        .map((score) => (
                                          <Fragment key={`${evaluator.name}-${evaluator.team}-Score`}>
                                            <TableCell>{score.isNvt ? "NVT" : score.answer}</TableCell>
                                          </Fragment>
                                        ))
                                    ) : (
                                      <Fragment key={`${evaluator.name}-${evaluator.team}-Score`}>
                                        <TableCell>/</TableCell>
                                      </Fragment>
                                    )
                                  )}
                                </TableRow>
                              ))}
                              <TableRow key={`${role.name}-comment`} className="tableRow" style={{ backgroundColor: "lightgrey" }}>
                                <TableCell>{role.name}</TableCell>
                                <TableCell>
                                  {rr.name}
                                  {rr.phase && ` (${rr.phase})`}
                                </TableCell>
                                <TableCell></TableCell>
                                {evaluators?.map((evaluator) =>
                                  rr.comments.filter((comment) => comment.evaluator.evaluatorName === evaluator.name && comment.evaluator.teamName === evaluator.team).length >
                                  0 ? (
                                    rr.comments
                                      .filter((comment) => comment.evaluator.evaluatorName === evaluator.name && comment.evaluator.teamName === evaluator.team)
                                      .map((comment) => (
                                        <Fragment key={`${evaluator.name}-${evaluator.team}-Comment`}>
                                          <TableCell>{comment.commentText}</TableCell>
                                        </Fragment>
                                      ))
                                  ) : (
                                    <Fragment key={`${evaluator.name}-${evaluator.team}-Comment`}>
                                      <TableCell></TableCell>
                                    </Fragment>
                                  )
                                )}
                              </TableRow>
                            </Fragment>
                          );
                        })
                      )}
                      <TableRow key={"general-comment"} className="tableRow" style={{ backgroundColor: "lightgrey" }}>
                        <TableCell colSpan={3}>Algeme comment</TableCell>
                        {evaluators?.map((evaluator) =>
                          scoreDetails.generalComments
                            .filter((comment) => comment.evaluator.evaluatorName === evaluator.name && comment.evaluator.teamName === evaluator.team)
                            .map((comment) => <TableCell key={`general-comment-${evaluator.name}-${evaluator.team}`}>{comment.commentText}</TableCell>)
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
