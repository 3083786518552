import { Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Switch, Tooltip } from "@mui/material";
import { AllRolesWithResponsibilitiesResponse, ExternalEvaluator, ExternalEvaluatorFromTeamMemberResponse, TeamMemberResponse } from "../../api/types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { UpdateExternalEvaluatorDialog } from "./UpdateExternalEvaluatorDialog";

type UserDetailExternalEvaluatorsProps = {
  teamMemberData: TeamMemberResponse;
  roleData: AllRolesWithResponsibilitiesResponse;
  updateExternalEvaluators: (externalEvaluators: ExternalEvaluator[]) => void;
};

export function UserDetailExternalEvaluators({ teamMemberData, roleData, updateExternalEvaluators }: UserDetailExternalEvaluatorsProps) {
  const [externalEvaluatorToEdit, setExternalEvaluatorToEdit] = useState<ExternalEvaluatorFromTeamMemberResponse>();

  const handleDelete = (externalEvaluatorToDelete: ExternalEvaluatorFromTeamMemberResponse) => {
    var mappedEvaluators = teamMemberData!.externalEvaluators
      .filter((e) => e.aggregateKey !== externalEvaluatorToDelete.aggregateKey)
      .map((e) => ({
        firstName: e.firstName,
        name: e.name,
        email: e.email,
        isActive: e.isActive,
        rolesToEvaluate: e.rolesToEvaluate.map((r) => r.blueprintRoleId),
      }));

    updateExternalEvaluators(mappedEvaluators);
  };

  const handleToggleActivate = (externalEvaluatorToActivate: ExternalEvaluatorFromTeamMemberResponse) => {
    var mappedEvaluators = teamMemberData!.externalEvaluators.map((e) => ({
      firstName: e.firstName,
      name: e.name,
      email: e.email,
      isActive: e.aggregateKey === externalEvaluatorToActivate.aggregateKey ? !e.isActive : e.isActive,
      rolesToEvaluate: e.rolesToEvaluate.map((r) => r.blueprintRoleId),
    }));

    updateExternalEvaluators(mappedEvaluators);
  };

  const handleUpdateExternalEvaluator = (updatedExternalEvaluator: ExternalEvaluator) => {
    setExternalEvaluatorToEdit(undefined);
    var mappedEvaluators = teamMemberData!.externalEvaluators.map((e) => ({
      aggregateKey: e.aggregateKey,
      firstName: updatedExternalEvaluator.aggregateKey === e.aggregateKey ? updatedExternalEvaluator.firstName : e.firstName,
      name: updatedExternalEvaluator.aggregateKey === e.aggregateKey ? updatedExternalEvaluator.name : e.name,
      email: updatedExternalEvaluator.aggregateKey === e.aggregateKey ? updatedExternalEvaluator.email : e.email,
      isActive: e.isActive,
      rolesToEvaluate: updatedExternalEvaluator.aggregateKey === e.aggregateKey ? updatedExternalEvaluator.rolesToEvaluate : e.rolesToEvaluate.map((r) => r.blueprintRoleId),
    }));
    updateExternalEvaluators(mappedEvaluators);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className="tableHeader">
            <TableRow>
              <TableCell>Externe evaluatoren</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      {!!externalEvaluatorToEdit && (
        <UpdateExternalEvaluatorDialog
          open={true}
          roles={roleData.roles.filter((role) => teamMemberData.roles?.findIndex((r) => r.blueprintRoleId === role.blueprintRoleId) !== -1)}
          externalEvaluatorToEdit={externalEvaluatorToEdit}
          handleClose={() => setExternalEvaluatorToEdit(undefined)}
          handleSave={handleUpdateExternalEvaluator}
        />
      )}

      {teamMemberData.externalEvaluators?.length === 0 && (
        <TableContainer key={"noexternalevaluators"} component={Paper} sx={{ mt: "1rem" }}>
          <Table>
            <TableBody>
              <TableRow className="tableRow">
                <TableCell>Nog geen externe evaluatoren toegewezen.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {teamMemberData.externalEvaluators?.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: "1rem" }} key={"externalevaluatorcontainer"}>
          <Table>
            <TableHead className="tableHeader">
              <TableRow>
                <TableCell>Naam</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Rollen te evalueren</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMemberData.externalEvaluators.map((externalEvaluator) => (
                <TableRow className="tableRow" key={`externalevaluator_row_for_${externalEvaluator.firstName}${externalEvaluator.name}${externalEvaluator.email}${Math.random()}`}>
                  <TableCell>{externalEvaluator.firstName + " " + externalEvaluator.name}</TableCell>
                  <TableCell>{externalEvaluator.email}</TableCell>
                  <TableCell>{externalEvaluator.rolesToEvaluate.map((r) => r.name).join(", ")}</TableCell>
                  <TableCell align="right">
                    <Switch checked={externalEvaluator.isActive} onChange={() => handleToggleActivate(externalEvaluator)} />
                    <Tooltip title="Bewerken">
                      <IconButton onClick={() => setExternalEvaluatorToEdit(externalEvaluator)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Verwijderen">
                      <IconButton onClick={() => handleDelete(externalEvaluator)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
