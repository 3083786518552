import { Grid, Stack, Button } from "@mui/material";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { HeaderCell, SortableTable } from "../components/SortableTable";
import { RoleResponsibilityDetails } from "../api/types";
import { EvaluatieScoreDetails } from "../api/types";
import { Cell, Pie, PieChart, Sector } from "recharts";

type ScoreGraphProps = {
  scoreDetails: EvaluatieScoreDetails[];
};

const renderActiveShape = ({ cx, cy, fill, payload, innerRadius, outerRadius, startAngle, endAngle, value }: any, part: number | null | undefined) => {
  const partEndAngle = part === undefined || part === null ? 0 : (360 / 100) * part;

  const partInRoleY = cy - outerRadius - 20;
  const partColor = "#82ca9d";
  const fontSize = payload.name.length < 8 ? "14px" : "10px";

  return (
    <g>
      <text fontSize={fontSize} x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name} ({(value as number).toString().replace(".", ",")}%)
      </text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      <Sector cx={cx} cy={cy} startAngle={0} endAngle={partEndAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={partColor} />
      <text x={cx} y={partInRoleY} textAnchor="middle" fill={partColor}>{`Aandeel in rol ${part?.toString().replace(".", ",")}%`}</text>
    </g>
  );
};

export function ScoreGraph({ scoreDetails }: ScoreGraphProps) {
  const [selectedRoleName, setSelectedRoleName] = useState<string>();

  const roleResponsibilityHeaders: HeaderCell<RoleResponsibilityDetails>[] = [
    {
      id: "name",
      label: "Rolverantwoordelijkheid",
    },
    {
      id: "score",
      label: "Score",
      render: (rr) => (rr.score ? rr.score : "NVT"),
    },
    {
      id: "weight",
      label: "Gewicht",
    },
  ];

  return (
    <Grid container>
      {selectedRoleName && (
        <>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <b>{selectedRoleName}</b>
              <Button onClick={() => setSelectedRoleName(undefined)}>
                <ArrowBackIcon />
                terug naar overzicht
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <SortableTable<RoleResponsibilityDetails>
              headers={roleResponsibilityHeaders}
              rows={scoreDetails.filter((score) => score.rolName === selectedRoleName).flatMap((score) => score.roleResponsibilities)}
              defaultSortedOn="name"
              defaultSortedDesc={false}
            />
          </Grid>
        </>
      )}
      {!selectedRoleName &&
        scoreDetails.map((scoreDetail) => (
          <Grid item xs={12} md={4} key={scoreDetail.rolName} minWidth={210}>
            <Button onClick={() => setSelectedRoleName(scoreDetail.rolName)}>
              <PieChart width={200} height={250} style={{ cursor: "pointer" }}>
                <Pie
                  activeIndex={0}
                  activeShape={(props) => renderActiveShape(props, scoreDetail.part)}
                  data={[
                    { name: scoreDetail.rolName, value: scoreDetail.score !== undefined && scoreDetail.score !== null ? scoreDetail.score : 0 },
                    { name: "rest", value: scoreDetail.score !== undefined && scoreDetail.score !== null ? 100 - scoreDetail.score : 100 },
                  ]}
                  cx={100}
                  cy={125}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  name="test"
                  isAnimationActive={false}
                >
                  {scoreDetail.score! && <Cell key={`cell-${0}`} fill="#8884d8" />}
                  <Cell key={`cell-${1}`} fill="#d3d3d3" />
                </Pie>
              </PieChart>
            </Button>
          </Grid>
        ))}
    </Grid>
  );
}
