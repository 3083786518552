import { format, isValid, parseISO } from "date-fns";
import { nl } from "date-fns/locale";

export const DEFAULT_DATE_STRING_FORMAT = "dd/MM/yyyy";
export const DEFAULT_DATETIME_STRING_FORMAT = "dd/MM/yyyy HH:mm";

export function formatDate(date: Date, formatString = DEFAULT_DATE_STRING_FORMAT): string {
  if (!isValid(date)) return "Ongeldige datum";
  return format(date, formatString, { locale: nl });
}

export function formatUTCStringToLocal(isoString?: string, formatString = DEFAULT_DATETIME_STRING_FORMAT): string {
  if (!isoString) return "";
  const date = parseISO(isoString);
  return formatDate(date, formatString);
}
