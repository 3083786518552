import { Grid } from "@mui/material";
import { GetFormQuestionWithAnswer } from "../../api/types";
import { SliderQuestion } from "../../components/SliderQuestion";
import styles from "./FormQuestionDetail.module.scss";

type FormQuestionDetailProps = {
  question: GetFormQuestionWithAnswer;
  isDisabled: boolean;
  saveQuestion: (questionKey: string, answer: number | null, isNvt: boolean | null) => void;
};

export function FormQuestionDetail({ question, isDisabled, saveQuestion }: FormQuestionDetailProps) {
  const marks = question.benchmarks.map((b) => ({ value: b.score, label: b.description }));

  const handleAnswerChanged = (value: number | null, isNvt: boolean | null) => {
    saveQuestion(question.questionKey, value, isNvt);
  };

  return (
    <Grid item xs={12} md={6} className={styles.questionContainer}>
      <SliderQuestion
        title={question.question}
        selectedValue={question.answer || null}
        marks={marks}
        isNvt={question.isNvt ?? null}
        isDisabled={isDisabled}
        valueChanged={handleAnswerChanged}
      />
    </Grid>
  );
}
