import { Grid, Stack } from "@mui/material";
import { LoadingIndicator } from "./LoadingIndicator";

type GridItemLoadingAndDataCheckerProps = {
  isLoading?: boolean;
  hasData?: boolean;
};

export function GridItemLoadingAndDataChecker({ isLoading = true, hasData = false }: GridItemLoadingAndDataCheckerProps) {
  return isLoading || !hasData ? (
    <Grid item xs={12}>
      <Stack direction="row" className="gridItemLoadingIndicatorAndDataChecker">
        {isLoading ? <LoadingIndicator isLoading={isLoading} /> : !hasData ? "Geen data beschikbaar" : null}
      </Stack>
    </Grid>
  ) : null;
}
