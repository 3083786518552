import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

export const isValidNumber = (maybeNumber: string | number) => {
  const stringNumber = maybeNumber.toString();
  if (stringNumber.length > 1 && stringNumber.startsWith("0")) {
    return false;
  }
  if (stringNumber.length === 0) {
    return false;
  }
  const isNumber = Number.isInteger(+maybeNumber);
  if (isNumber) {
    return +maybeNumber >= 1 && +maybeNumber <= 100;
  }
  return false;
};

type WeightDialogProps = {
  open: boolean;
  name: string;
  initialValue: number;
  handleClose: (newValue: number, isCancelled: boolean) => void;
};

export function WeightDialog({ open, name, initialValue, handleClose }: WeightDialogProps) {
  const [error, setError] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<string>(initialValue.toString());

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    setError(!isValidNumber(newValue));
  };

  const handleCancel = () => {
    setError(false);
    setLocalValue(initialValue.toString());
    handleClose(initialValue, true);
  };

  const handleOk = () => {
    if (!error) {
      handleClose(+localValue, false);
    }
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Gewicht rolverantwoordelijkheid aanpassen</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: "1rem" }}>{name}</DialogContentText>
        <TextField
          type={"number"}
          inputProps={{ inputMode: "numeric" }}
          style={{ width: "30rem" }}
          value={localValue}
          onChange={onChange}
          error={error}
          helperText={error ? "Gewicht moet tussen 1 en 100 zitten en mag enkel cijfers bevatten" : null}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleCancel}>
          Annuleren
        </Button>
        <Button variant="contained" onClick={handleOk} disabled={error}>
          Bewaren
        </Button>
      </DialogActions>
    </Dialog>
  );
}
