import { Link } from "react-router-dom";
import { RoutePath } from "../App";
import ArrowIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";

type BreadcrumbProps = {
  path: BreadcrumbItem[];
  currentPage: string;
};

type BreadcrumbItem = {
  name: string;
  route: RoutePath;
};

export default function Breadcrumb({ path, currentPage }: BreadcrumbProps) {
  return (
    <div id="breadcrumb" style={{ marginBottom: "5px" }}>
      {path.map((item) => (
        <React.Fragment key={item.route}>
          <Link to={item.route}>{item.name}</Link>
          &nbsp;
          <ArrowIcon style={{ fontSize: 12 }} />
          &nbsp;
        </React.Fragment>
      ))}
      {currentPage}
    </div>
  );
}
