import { Accordion, AccordionSummary, Grid, Stack, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiClient, useApi } from "../../api/apiClient";
import { useQuery } from "react-query";
import { GridItemLoadingAndDataChecker } from "../../components/GridItemLoadingAndDataChecker";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useState } from "react";
import styles from "./ScoreOverview.module.scss";
import { ScoreGraph } from "../../components/ScoreGraph";

function getTeamScores(teamKey: string, contextId: string) {
  return function () {
    return apiClient["/teams/{teamKey}/contexts/{contextId}"].get({
      path: {
        teamKey: teamKey,
        contextId: contextId,
      },
    });
  };
}

type ScoreDetailProps = {
  teamKey: string;
  contextId: string;
};

export function ScoreDetail({ teamKey, contextId }: ScoreDetailProps) {
  const [expanded, setExpanded] = useState<string[]>([]);
  const { data: scoreData, isLoading: scoreDetailsAreLoading } = useQuery(["Team/contexts", contextId], useApi("Score details ophalen", getTeamScores(teamKey, contextId)));

  const handleSetExpanded = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? [...expanded, panel] : expanded.filter((e: string) => e !== panel));
  };

  if (scoreDetailsAreLoading || !scoreData)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  return (
    <Grid container spacing={2}>
      {scoreData.evaluees.map((evaluee) => (
        <Grid item xs={12} sm={6} md={4} sx={{ mb: "1rem" }} key={evaluee.personeelstoolId}>
          <Accordion expanded={expanded.indexOf(evaluee.personeelstoolId) !== -1} onChange={handleSetExpanded(evaluee.personeelstoolId)} className={styles.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={evaluee.personeelstoolId} id={evaluee.personeelstoolId} className={styles.accordionHeader}>
              <Typography variant="subtitle2" className={styles.header}>
                {evaluee.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ScoreGraph scoreDetails={evaluee.scores} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
      <GridItemLoadingAndDataChecker isLoading={scoreDetailsAreLoading} hasData={scoreData.evaluees.length > 0} />
    </Grid>
  );
}
