import Typography from "@mui/material/Typography";
import { Button, Card, Grid } from "@mui/material";
import { FormRoleResponsibilityDetail } from "./FormRoleResponsibilityDetail";
import styles from "./EditForm.module.scss";
import { DebouncedTextfield } from "../../components/DebouncedTextfield";
import { ConfirmSubmitFormDialog } from "./ConfirmSubmitFormDialog";
import { ConfirmAdminSaveFormDialog } from "./ConfirmAdminSaveFormDialog";
import { GetFormResponse, PostFormRoleResponsibility, PostParameters } from "../../api/types";
import { useEffect, useState } from "react";
import { apiClientWithPossibleAuth, useApi } from "../../api/apiClient";
import { useQueryClient, useMutation } from "react-query";

function postSaveForm({ path, body }: PostParameters<"/forms/{formKey}">) {
  return apiClientWithPossibleAuth["/forms/{formKey}"].post({
    path: path,
    body: {
      type: "application/json",
      value: body,
    },
  });
}

function postSubmitForm({ path }: PostParameters<"/forms/{formKey}/submit">) {
  return apiClientWithPossibleAuth["/forms/{formKey}/submit"].post({
    path: path,
  });
}

type EditFormAsAdminProps = {
  formData: GetFormResponse;
};

export function EditFormAsAdmin({ formData }: EditFormAsAdminProps) {
  const queryClient = useQueryClient();
  const [expandedInitial, setExpandedInitial] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [confirmingSubmit, setConfirmingSubmit] = useState(false);
  const [confirmingSave, setConfirmingSave] = useState(false);
  const [adminReadOnly, setAdminReadOnly] = useState(true);
  const [tempForm, setTempForm] = useState<GetFormResponse>(formData);

  const handleSetExpanded = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? [...expanded, panel] : expanded.filter((e: string) => e !== panel));
  };

  useEffect(() => {
    if (formData && !expandedInitial) {
      setExpanded(formData!.roleResponsibilities.map((rr) => rr.name));
      setExpandedInitial(true);
    }
  }, [formData, expandedInitial]);

  useEffect(() => {
    const unansweredQuestionsExist = (formData.roleResponsibilities.flatMap((rr) => rr.questions).filter((q) => q.isNvt === null && q.answer === null).length || 0) > 0;

    if (!unansweredQuestionsExist && !submitEnabled) {
      setSubmitEnabled(true);
    }
    if (unansweredQuestionsExist && submitEnabled) {
      setSubmitEnabled(false);
    }
  }, [formData, submitEnabled]);

  const { mutate: saveForm } = useMutation(useApi("Evaluatie opslaan", postSaveForm), {
    onMutate: () => queryClient.cancelQueries("Form/edit"),
    onSettled: () => queryClient.invalidateQueries("Form/edit"),
  });

  const { mutate: submitForm } = useMutation(useApi("Evaluatie indienen", postSubmitForm), {
    onMutate: () => queryClient.cancelQueries("Form/edit"),
    onSettled: () => queryClient.invalidateQueries("Form/edit"),
  });

  const handleSaveForm = () => {
    setConfirmingSave(true);
  };

  const handleConfirmSave = (isCancelled: boolean) => {
    setConfirmingSave(false);

    if (isCancelled) return;

    saveForm({
      path: { formKey: formData!.aggregateKey },
      body: {
        feedback: tempForm!.feedback,
        roleResponsibilities: tempForm!.roleResponsibilities.map(
          (rr) =>
            ({
              blueprintId: rr.blueprintId,
              roleResponsibilityVersion: rr.roleResponsibilityVersion,
              feedback: rr.feedback,
              questionAnswers: rr.questions,
            } as PostFormRoleResponsibility)
        ),
      },
    });
  };

  const handleSubmitForm = () => {
    setConfirmingSubmit(true);
  };

  const handleConfirmSubmit = (isCancelled: boolean) => {
    setConfirmingSubmit(false);

    if (isCancelled) return;

    setAdminReadOnly(true);

    submitForm({
      path: { formKey: formData!.aggregateKey },
    });
  };

  const handleFormFeedbackChange = (value: string) => {
    setTempForm({ ...tempForm, feedback: value });
  };

  const handleSaveRoleResponsibility = (roleResponsibilityBlueprintId: string, feedback: string) => {
    var roleresponsibilities = [...tempForm.roleResponsibilities];
    var rrId = roleresponsibilities.findIndex((rr) => rr.blueprintId === roleResponsibilityBlueprintId);

    roleresponsibilities[rrId] = { ...roleresponsibilities[rrId], feedback: feedback };

    setTempForm({ ...tempForm, roleResponsibilities: roleresponsibilities });
  };

  const handleSaveQuestion = (roleResponsibilityBlueprintId: string, questionKey: string, answer: number | null, isNvt: boolean | null) => {
    var roleresponsibilities = [...tempForm.roleResponsibilities];
    var rrId = roleresponsibilities.findIndex((rr) => rr.blueprintId === roleResponsibilityBlueprintId);

    var questionId = roleresponsibilities[rrId].questions.findIndex((q) => q.questionKey === questionKey);
    var questions = [...roleresponsibilities[rrId].questions];

    questions[questionId] = { ...questions[questionId], answer: answer, isNvt: isNvt };
    roleresponsibilities[rrId] = { ...roleresponsibilities[rrId], questions: questions };

    setTempForm({ ...tempForm, roleResponsibilities: roleresponsibilities });
  };

  return (
    <Grid container className={styles.container}>
      {adminReadOnly && (
        <Button variant="contained" color="primary" onClick={() => setAdminReadOnly(false)} className={styles.adminEditButton}>
          Evaluatie aanpassen
        </Button>
      )}
      <Card className={styles.card}>
        <Grid container>
          <Grid item xs={12}>
            <a className={styles.logo} href="https://qframe.be/">
              <span className={styles.label}>Qframe</span>
            </a>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {tempForm.assessedPerson === tempForm.evaluator && `Dit is een zelfevaluatie voor ${tempForm.assessedPerson}.`}
              {tempForm.assessedPerson !== tempForm.evaluator && `Dit is een evaluatie voor ${tempForm.assessedPerson}, ingevuld door ${tempForm.evaluator}.`}
            </Typography>
          </Grid>
          {tempForm.roleResponsibilities.map((rr) => (
            <FormRoleResponsibilityDetail
              roleResponsibility={rr}
              expanded={expanded.indexOf(rr.name) !== -1}
              isDisabled={adminReadOnly}
              setExpanded={handleSetExpanded}
              updateRoleResponsibility={(feedback) => handleSaveRoleResponsibility(rr.blueprintId, feedback)}
              key={rr.name}
              saveQuestion={(questionKey, answer, isNvt) => handleSaveQuestion(rr.blueprintId, questionKey, answer, isNvt)}
            />
          ))}
          <Grid item xs={12}>
            <DebouncedTextfield label={"Feedback"} value={tempForm.feedback} onChange={handleFormFeedbackChange} disabled={adminReadOnly} className={styles.textbox} />
          </Grid>
          <Grid item xs={12} className={styles.submitButton}>
            <Button variant="contained" color="primary" onClick={handleSaveForm} disabled={adminReadOnly} className={styles.saveButton}>
              Aanpassingen opslaan
            </Button>
            <ConfirmAdminSaveFormDialog open={confirmingSave} handleClose={handleConfirmSave} />
            <Button variant="contained" color="primary" onClick={handleSubmitForm} disabled={adminReadOnly || !submitEnabled}>
              Evaluatie indienen
            </Button>
            <ConfirmSubmitFormDialog open={confirmingSubmit} handleClose={handleConfirmSubmit} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
