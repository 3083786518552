import { Configuration, PopupRequest } from "@azure/msal-browser";
import { env } from "../env";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    //clientId: "94891561-afb7-42bd-a802-a3de27362ac4",
    clientId: env.REACT_APP_AUTH_CLIENTID,
    authority: "https://login.microsoftonline.com/49c3d703-3579-47bf-a888-7c913fbdced9",
    redirectUri: window.location.origin + "/auth/login",
    postLogoutRedirectUri: window.location.origin + "/auth/logout",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [`${env.REACT_APP_AUTH_CLIENTID}/.default`],
  // extraQueryParameters: {
  //     'domain_hint': 'cronos.be'
  // }
};
