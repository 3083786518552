import Breadcrumb from "../../components/Breadcrumb";
import { RouteComponentProps } from "react-router";
import { Button, Grid, Alert, Stack, Tab, Box } from "@mui/material";
import { apiClient, useApi } from "../../api/apiClient";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddIcon from "@mui/icons-material/Add";
import { AddTeamMemberRolesDialog } from "./AddTeamMemberRolesDialog";
import { useEffect, useState } from "react";
import { ExternalEvaluator, PutParameters } from "../../api/types";
import { useUser } from "../../components/UserContext";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { UserDetailRoleResponsibilities } from "./UserDetailRoleResponsibilities";
import { UserDetailExternalEvaluators } from "./UserDetailExternalEvaluators";
import { UpdateExternalEvaluatorDialog } from "./UpdateExternalEvaluatorDialog";

function getTeamMember(teamId: string, memberId: string) {
  return function () {
    return apiClient["/teams/{id}/members/{memberId}"].get({
      path: {
        id: teamId,
        memberId: memberId,
      },
    });
  };
}

function getAllRolesAndRoleResponsibilities() {
  return function () {
    return apiClient["/roles/allwithresponsibilities"].get({});
  };
}

async function postUpdateTeamMemberRoleResponsibilities({ path, body }: PutParameters<"/teams/{id}/members/{memberId}/roleresponsibilities">) {
  return apiClient["/teams/{id}/members/{memberId}/roleresponsibilities"].put({
    path: path,
    body: {
      type: "application/json",
      value: body,
    },
  });
}

async function postUpdateTeamMemberExternalEvaluators({ path, body }: PutParameters<"/teams/{id}/members/{memberId}/externalevaluators">) {
  return apiClient["/teams/{id}/members/{memberId}/externalevaluators"].put({
    path: path,
    body: {
      type: "application/json",
      value: body,
    },
  });
}

type UserDetailProps = RouteComponentProps<{
  aggregateKey: string;
  personeelsToolId: string;
}>;

export function UserDetail(props: UserDetailProps) {
  const queryCache = useQueryClient();
  const { isAdmin, isLoading: isAdminIsLoading } = useUser();
  const { aggregateKey, personeelsToolId } = props.match.params;
  const [openAddRoleDialog, setOpenAddRoleDialog] = useState(false);
  const [openAddExternalEvaluatorDialog, setOpenAddExternalEvaluatorDialog] = useState(false);
  const [selectedMemberRoleResponsibilities, setSelectedMemberRoleResponsibilities] = useState<string[]>();
  const [openTabPanel, setOpenTabPanel] = useState<string>("rollen");

  const { data: teamMemberData, isLoading: teamMemberIsLoading } = useQuery(
    ["Team/member", aggregateKey],
    useApi("Teamlid ophalen", getTeamMember(aggregateKey, personeelsToolId))
  );
  const { data: roleData, isLoading: rolesIsLoading } = useQuery(
    ["RolesWithResponisibilities", aggregateKey],
    useApi("Rollen en rolverantwoordelijkheden ophalen", getAllRolesAndRoleResponsibilities())
  );

  const { mutate: updateTeamMemberRoleResponsibilities } = useMutation(useApi("Rolverantwoordelijkheden voor gebruiker updaten", postUpdateTeamMemberRoleResponsibilities), {
    onMutate: () => queryCache.cancelQueries("Team/member"),
    onSettled: () => queryCache.invalidateQueries("Team/member"),
  });

  const { mutate: updateTeamMemberExternalEvaluators } = useMutation(useApi("Externe evaluatoren voor gebruiker updaten", postUpdateTeamMemberExternalEvaluators), {
    onMutate: () => queryCache.cancelQueries("Team/member"),
    onSettled: () => queryCache.invalidateQueries("Team/member"),
  });

  useEffect(() => {
    const saveRoleResponsibilities = () => {
      updateTeamMemberRoleResponsibilities({
        path: { id: aggregateKey, memberId: personeelsToolId },
        body: selectedMemberRoleResponsibilities ?? [],
      });
    };

    if (selectedMemberRoleResponsibilities !== undefined) saveRoleResponsibilities();
  }, [selectedMemberRoleResponsibilities, aggregateKey, personeelsToolId, updateTeamMemberRoleResponsibilities]);

  const addNewRoleAndAllHisRoleResponsibilities = (newRoleIds: string[]) => {
    const newRoleResponsibilities = roleData!.roles
      .filter((role) => newRoleIds.findIndex((x) => x === role.blueprintRoleId) !== -1)
      .flatMap((role) => role.roleResponsibilities)
      .map((rr) => rr.blueprintRoleResponsibilityId);
    setSelectedMemberRoleResponsibilities(
      teamMemberData?.roles
        ?.flatMap((r) => r.roleResponsibilities)
        .map((rr) => rr.blueprintRoleResponsibilityId)
        .concat(newRoleResponsibilities)
    );
  };

  const handleAddNew = () => {
    if (openTabPanel === "rollen") {
      setOpenAddRoleDialog(true);
    }
    if (openTabPanel === "externeeval") {
      setOpenAddExternalEvaluatorDialog(true);
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setOpenTabPanel(newValue);
  };

  const handleSaveNewExternalEvaluator = (externalEvaluatorToAdd: ExternalEvaluator) => {
    setOpenAddExternalEvaluatorDialog(false);
    var mappedEvaluators = teamMemberData!.externalEvaluators.map((e) => ({
      firstName: e.firstName,
      name: e.name,
      email: e.email,
      isActive: e.isActive,
      rolesToEvaluate: e.rolesToEvaluate.map((r) => r.blueprintRoleId),
    }));
    updateTeamMemberExternalEvaluators({
      path: { id: aggregateKey, memberId: personeelsToolId },
      body: mappedEvaluators.concat([externalEvaluatorToAdd]),
    });
  };

  const handleUpdateExternalEvaluators = (externalEvaluators: ExternalEvaluator[]) => {
    updateTeamMemberExternalEvaluators({
      path: { id: aggregateKey, memberId: personeelsToolId },
      body: externalEvaluators,
    });
  };

  if (teamMemberIsLoading || !teamMemberData || rolesIsLoading || !roleData || isAdminIsLoading)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  if (!isAdmin && !teamMemberData.currentUserIsTeamBeheerder) return <Alert severity="error">Je moet admin of teambeheerder zijn om deze pagina te mogen bekijken.</Alert>;

  return (
    <TabContext value={openTabPanel}>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb
            path={[
              { name: "Home", route: "/" },
              { name: "Mijn team", route: `/teambeheerder/mijnteam/${aggregateKey}` },
            ]}
            currentPage={teamMemberData.name!}
          />{" "}
        </Grid>
        <Grid item xs={12}>
          <h1>{teamMemberData.name}</h1>
        </Grid>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Rollen &amp; rolverantwoordelijkheden" value="rollen" sx={{ fontSize: "24px", textTransform: "none", maxWidth: "none" }} />
              <Tab label="Externe evaluatoren" value="externeeval" sx={{ fontSize: "24px", textTransform: "none", maxWidth: "none" }} />
            </TabList>
          </Box>
          <Button variant="contained" onClick={handleAddNew} endIcon={<AddIcon />}>
            Toevoegen
          </Button>
          <AddTeamMemberRolesDialog
            open={openAddRoleDialog}
            handleClose={() => setOpenAddRoleDialog(false)}
            selectedMemberRoles={teamMemberData.roles?.map((r) => r.blueprintRoleId) ?? []}
            setNewSelectedMemberRoles={addNewRoleAndAllHisRoleResponsibilities}
          />
          <UpdateExternalEvaluatorDialog
            open={openAddExternalEvaluatorDialog}
            roles={roleData.roles.filter((role) => teamMemberData.roles?.findIndex((r) => r.blueprintRoleId === role.blueprintRoleId) !== -1)}
            handleClose={() => setOpenAddExternalEvaluatorDialog(false)}
            handleSave={handleSaveNewExternalEvaluator}
          />
        </Grid>
        <Grid item xs={12}>
          <TabPanel value="rollen" sx={{ padding: "1rem 0px" }}>
            <UserDetailRoleResponsibilities teamMemberData={teamMemberData} roleData={roleData} setSelectedMemberRoleResponsibilities={setSelectedMemberRoleResponsibilities} />
          </TabPanel>
          <TabPanel value="externeeval" sx={{ padding: "1rem 0px" }}>
            <UserDetailExternalEvaluators teamMemberData={teamMemberData} roleData={roleData} updateExternalEvaluators={handleUpdateExternalEvaluators} />
          </TabPanel>
        </Grid>
      </Grid>
    </TabContext>
  );
}
