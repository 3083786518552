import Typography from "@mui/material/Typography";
import Breadcrumb from "../../components/Breadcrumb";
import { apiClient, useApi } from "../../api/apiClient";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, IconButton, Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Stack, Tooltip } from "@mui/material";
import { RouteComponentProps, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AddTeamMemberDialog } from "./AddTeamMemberDialog";
import { DeleteParameters, PostParameters } from "../../api/types";
import { useState } from "react";
import { GridItemLoadingAndDataChecker } from "../../components/GridItemLoadingAndDataChecker";
import { DeleteTeamMemberDialog } from "./DeleteTeamMemberDialog";
import { useUser } from "../../components/UserContext";
import { GenerateEvaluationsDialog } from "./GenerateEvaluationsDialog";
import { env } from "../../env";

function getTeam(id: string) {
  return function () {
    return apiClient["/teams/{id}"].get({
      path: {
        id: id,
      },
    });
  };
}

function postAddTeamMembers({ path, body }: PostParameters<"/teams/{id}/members/add">) {
  return apiClient["/teams/{id}/members/add"].post({
    path: path,
    body: {
      type: "application/json",
      value: body,
    },
  });
}

function deleteTeamMember({ path }: DeleteParameters<"/teams/{id}/members/{memberId}">) {
  return apiClient["/teams/{id}/members/{memberId}"].delete({
    path: path,
  });
}

function postGenerateEvaluations({ body }: PostParameters<"/forms">) {
  return apiClient["/forms"].post({
    body: {
      type: "application/json",
      value: body,
    },
  });
}

type TeamDetailProps = RouteComponentProps<{
  aggregateKey: string;
}>;

export function TeamDetail(props: TeamDetailProps) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { isAdmin } = useUser();
  const { aggregateKey } = props.match.params;

  const [openAddTeamMemberDialog, setOpenAddTeamMemberDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openGenerateEvaluationsDialog, setOpenGenerateEvaluationsDialog] = useState(false);

  const [memberIdsToAdd, setMemberIdsToAdd] = useState<string[]>([]);
  const [memberToDelete, setMemberToDelete] = useState<{ memberId: string; memberName: string } | null>(null);

  const [selectedTeamMembers, setSelectedTeamMembers] = useState<{ teamKey: string; personeeltoolId: string }[]>([]);

  const { data, isLoading } = useQuery(["Team/mijnteam", aggregateKey], useApi("Mijn team ophalen", getTeam(aggregateKey)));

  const { mutate: addTeamMembers } = useMutation(useApi("Teamleden toevoegen", postAddTeamMembers), {
    onMutate: () => queryClient.cancelQueries("Team/mijnteam"),
    onSettled: () => queryClient.invalidateQueries("Team/mijnteam"),
  });

  const { mutate: removeTeamMember } = useMutation(useApi("Teamlid verwijderen", deleteTeamMember), {
    onMutate: () => queryClient.cancelQueries("Team/mijnteam"),
    onSettled: () => queryClient.invalidateQueries("Team/mijnteam"),
  });

  const generateEvaluations = useApi("Evaluatieformulieren genereren", postGenerateEvaluations);

  const handleAddNew = () => {
    setOpenAddTeamMemberDialog(true);
  };

  const handleCloseDialog = (isCanceled: boolean) => {
    if (!isCanceled) {
      addTeamMembers({
        path: { id: aggregateKey },
        body: memberIdsToAdd,
      });
    }
    setMemberIdsToAdd([]);
    setOpenAddTeamMemberDialog(false);
  };

  const handleNavigateToUserDetail = (userId: string) => {
    history.push(`/teambeheerder/mijnteam/${aggregateKey}/teamlid/${userId}`);
  };

  const handleOpenDeleteDialog = (memberId: string, memberName: string) => {
    setMemberToDelete({ memberId: memberId, memberName: memberName });
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = (isCancelled: boolean) => {
    if (!isCancelled && data && memberToDelete) {
      removeTeamMember({ path: { id: data?.aggregateKey, memberId: memberToDelete.memberId } });
    }
    setMemberToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleCloseGenerateEvaluationsDialog = async (isCancelled: boolean, label?: string | null) => {
    setOpenGenerateEvaluationsDialog(false);

    if (isCancelled) return;

    const teamMembersToEvaluate = someAreChecked() ? selectedTeamMembers : getAllTeamMembers();

    await generateEvaluations({
      body: {
        label: label,
        teamMembers: teamMembersToEvaluate,
      },
    });
  };

  const handleToggleTeamMemberCheckbox = (target: HTMLInputElement, teamKey: string, personeeltoolId: string) => {
    const addTeamMember = target.checked;

    if (addTeamMember) {
      setSelectedTeamMembers([...selectedTeamMembers, { teamKey, personeeltoolId }]);
    } else {
      // remove teamMember
      setSelectedTeamMembers(selectedTeamMembers.filter((tm) => tm.personeeltoolId !== personeeltoolId));
    }
  };

  const getAllTeamMembers = () =>
    data?.teamMembers.map((tm) => ({
      teamKey: data.aggregateKey,
      personeeltoolId: tm.personeeltoolId,
    })) ?? [];

  const handleToggleAllCheckbox = (target: HTMLInputElement) => {
    const addAll = target.checked;

    if (addAll) {
      setSelectedTeamMembers(getAllTeamMembers());
    } else {
      // remove all
      setSelectedTeamMembers([]);
    }
  };

  const allAreChecked = () => selectedTeamMembers.length === data?.teamMembers.length;
  const someAreChecked = () => selectedTeamMembers.length > 0;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb path={[{ name: "Home", route: "/" }]} currentPage="Mijn team" />
        </Grid>
        <Grid item xs={12}>
          <h1>Mijn team</h1>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Hieronder vind je een overzicht van je team. Indien je teambeheerder bent van je team kan je deze samenstelling wijzigen. Via deze pagina kan je de mensen aan een rol/
            rolverantwoordelijkheid koppelen door per persoon op het potlood te klikken.
            <br />
            Door middel van de knop “Teamrolverdeling” kan je binnen je team de nodige rolverantwoordelijkheden bij de juiste persoon zetten. Je kan ook evaluaties genereren voor
            gans je team of per persoon door deze persoon te selecteren en te drukken op “genereer evaluaties voor…” .
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Grid item>
            <h2>Team: {data?.name}</h2>
          </Grid>
          <Grid item>
            <Button variant="contained" component={Link} to={`/teambeheerder/mijnteam/${aggregateKey}/rolverdeling`} style={{ marginRight: "1rem" }}>
              Team rolverdeling
            </Button>
            {data?.aggregateKey && (isAdmin || data.isMyTeam) && (
              <Button
                variant="contained"
                style={{ marginRight: "1rem" }}
                color="secondary"
                onClick={() => (env.REACT_APP_DISABLE_GENERATE_EVALUATIONS === "true" ? null : setOpenGenerateEvaluationsDialog(true))}
                disabled={env.REACT_APP_DISABLE_GENERATE_EVALUATIONS === "true"}
              >
                Genereer evaluatieformulieren voor {someAreChecked() ? "Geselecteerde" : "Alle"} teamleden
              </Button>
            )}
            {(isAdmin || data?.isMyTeam) && (
              <>
                <Button variant="contained" component={Link} to={`/teambeheerder/mijnteam/${aggregateKey}/evaluaties`} style={{ marginRight: "1rem" }}>
                  Evaluaties
                </Button>
                {(isAdmin || env.REACT_APP_DISABLE_SCORES === "false") && (
                  <Button variant="contained" component={Link} to={`/teambeheerder/mijnteam/${aggregateKey}/scoreoverzicht`} style={{ marginRight: "1rem" }}>
                    Scores
                  </Button>
                )}
                <Button variant="contained" onClick={handleAddNew} endIcon={<AddIcon />}>
                  Toevoegen
                </Button>
              </>
            )}
            <AddTeamMemberDialog
              open={openAddTeamMemberDialog}
              handleClose={handleCloseDialog}
              setMemberIdsToAdd={setMemberIdsToAdd}
              memberIdsToAdd={memberIdsToAdd}
              existingMembers={data?.teamMembers?.map((tm) => tm.name!)}
            />
            <GenerateEvaluationsDialog open={openGenerateEvaluationsDialog} handleClose={handleCloseGenerateEvaluationsDialog} team={data?.name ?? ""} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="tableHeader">
                <TableRow>
                  {(isAdmin || data?.isMyTeam) && (
                    <TableCell>
                      <Checkbox indeterminate={someAreChecked() && !allAreChecked()} checked={allAreChecked()} onChange={(e) => handleToggleAllCheckbox(e.target)} />
                    </TableCell>
                  )}
                  <TableCell>Naam</TableCell>
                  <TableCell>Teamrol</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.teamMembers?.map((t) => (
                  <TableRow key={t.personeeltoolId} className="tableRow">
                    {(isAdmin || data?.isMyTeam) && (
                      <TableCell>
                        <Checkbox
                          checked={selectedTeamMembers.some((tm) => tm.teamKey === data.aggregateKey && tm.personeeltoolId === t.personeeltoolId)}
                          onChange={(e) => handleToggleTeamMemberCheckbox(e.target, data.aggregateKey, t.personeeltoolId)}
                        />
                      </TableCell>
                    )}
                    <TableCell>{t.name}</TableCell>
                    <TableCell>{t.roles}</TableCell>
                    <TableCell className="smallPadding">
                      <Stack direction={"row"} justifyContent={"flex-end"}>
                        {(data?.isMyTeam || isAdmin) && (
                          <>
                            <Tooltip title="Bewerken">
                              <IconButton onClick={() => handleNavigateToUserDetail(t.personeeltoolId)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Verwijderen">
                              <IconButton onClick={() => handleOpenDeleteDialog(t.personeeltoolId, t.name)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
                {data && memberToDelete && (
                  <DeleteTeamMemberDialog open={openDeleteDialog} teamMember={memberToDelete.memberName} team={data.name} handleClose={handleCloseDeleteDialog} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <GridItemLoadingAndDataChecker isLoading={isLoading} hasData={data && data.teamMembers.length > 0} />
      </Grid>
    </>
  );
}
