import { Button, Divider, Grid } from "@mui/material";
import styles from "../Home.module.scss";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link as RouterLink } from "react-router-dom";
import { apiClient, useApi } from "../../api/apiClient";
import { useQuery } from "react-query";

function getMyTeams() {
  return apiClient["/teams/my"].get({});
}

export function MijnTeams() {
  const { data, isLoading } = useQuery(["Team/mijnteams"], useApi("Mijn teams ophalen", getMyTeams));

  if (isLoading) return null;

  const numberOfTeams = data?.teams?.length ?? 0;
  if (numberOfTeams === 0) return null;
  
  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="left" className={styles.divider}>
          Teams
        </Divider>
      </Grid>
      <Grid item xs={12}>
        {data?.teams &&
          data.teams.map((team) => (
            <Button
              key={team.aggregateKey}
              component={RouterLink}
              to={`/teambeheerder/mijnteam/${team.aggregateKey}`}
              variant="contained"
              color={team.isMyTeam ? "primary" : "secondary"}
              startIcon={<GroupsIcon />}
              className={styles.menuButton}
            >
              {team.teamName}
            </Button>
          ))}
      </Grid>
    </>
  );
}
