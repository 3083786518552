import { debounce, TextField } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

type DebouncedTextfieldProps = {
  label: string;
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
  className: string;
};

export function DebouncedTextfield({ label, value, disabled, onChange, className }: DebouncedTextfieldProps) {
  const [tempValue, setTempValue] = useState<string>(value);

  const saveFeedback = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange]
  );

  const debouncedSaveHandler = useMemo(() => debounce(saveFeedback, 2000), [saveFeedback]);

  const changeFeedback = (value: string) => {
    setTempValue(value);
    debouncedSaveHandler(value);
  };

  return (
    <TextField
      multiline
      label={label}
      value={tempValue}
      onChange={(e) => changeFeedback(e.target.value)}
      disabled={disabled}
      className={className}
      sx={{
        ...(disabled && {
          "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "#eee",
          },
          ...(tempValue !== "" && {
            "& .MuiInputBase-input.Mui-disabled": {
              color: "black",
              WebkitTextFillColor: "black",
              backgroundColor: "#eee",
            },
          }),
        }),
      }}
    />
  );
}
