import { ErrorResponse } from "./types";

export function handleApiResponse<TInput extends { type: number; value: any }>(
  result: TInput,
  action: string,
  onSuccess?: (message: string) => void,
  onError?: (responseCode: number, error?: ErrorResponse) => void,
  onFinally?: () => void
): TInput extends { type: 200; value: infer T } ? T : undefined {
  switch (result.type) {
    case 200:
      return result.value;
    case 201:
    case 202:
    case 203:
    case 204:
    case 205:
      if (onSuccess) onSuccess(`${action} is gelukt`);
      break;
    default:
      if (onError) onError(result.type, result.value);
      break;
  }
  if (onFinally) onFinally();
  return undefined as any;
}
