import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetFormRoleResponsibility } from "../../api/types";
import { FormQuestionDetail } from "./FormQuestionDetail";
import styles from "./FormRoleResponsibilityDetail.module.scss";
import React from "react";
import { DebouncedTextfield } from "../../components/DebouncedTextfield";

type FormRoleResponsibilityDetailProps = {
  roleResponsibility: GetFormRoleResponsibility;
  expanded: boolean;
  isDisabled: boolean;
  setExpanded: (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => void;
  updateRoleResponsibility: (feedback: string) => void;
  saveQuestion: (questionKey: string, answer: number | null, isNvt: boolean | null) => void;
};

export function FormRoleResponsibilityDetail({ roleResponsibility, expanded, isDisabled, setExpanded, updateRoleResponsibility, saveQuestion }: FormRoleResponsibilityDetailProps) {
  const handleChangeFeedback = (value: string) => {
    updateRoleResponsibility(value);
  };

  return (
    <Grid item xs={12} sx={{ mb: "1rem" }}>
      <Accordion expanded={expanded} onChange={setExpanded(roleResponsibility.name)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={roleResponsibility.name} id={roleResponsibility.name} className={styles.accordionHeader}>
          <Typography variant="subtitle2" className={styles.header}>
            Rolverantwoordelijkheid: {roleResponsibility.name} (Rol: {roleResponsibility.role}
            {roleResponsibility.phase && ` - Fase: ${roleResponsibility.phase}`})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {roleResponsibility.questions.map((question) => (
              <FormQuestionDetail question={question} key={`${question.question}${question.questionKey}`} isDisabled={isDisabled} saveQuestion={saveQuestion} />
            ))}
            <Grid item xs={12}>
              <DebouncedTextfield label="Feedback" value={roleResponsibility.feedback} onChange={handleChangeFeedback} disabled={isDisabled} className={styles.textbox} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
