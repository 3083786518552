import { IconButton, Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from "@mui/material";
import { AllRolesWithResponsibilitiesResponse, TeamMemberResponse } from "../../api/types";
import DeleteIcon from "@mui/icons-material/Delete";

type UserDetailRoleResponsibilitiesProps = {
  teamMemberData: TeamMemberResponse;
  roleData: AllRolesWithResponsibilitiesResponse;
  setSelectedMemberRoleResponsibilities: (roleResponsibilitiesToSave: string[]) => void;
};

export function UserDetailRoleResponsibilities({ teamMemberData, roleData, setSelectedMemberRoleResponsibilities }: UserDetailRoleResponsibilitiesProps) {
  const toggleActivateRoleResponsibility = (roleResponsibilityId: string) => {
    if (
      teamMemberData.roles
        ?.flatMap((r) => r.roleResponsibilities)
        ?.map((rr) => rr.blueprintRoleResponsibilityId)
        ?.findIndex((srr) => srr === roleResponsibilityId) === -1
    ) {
      setSelectedMemberRoleResponsibilities(
        teamMemberData.roles
          ?.flatMap((r) => r.roleResponsibilities)
          ?.map((rr) => rr.blueprintRoleResponsibilityId)
          .concat(roleResponsibilityId)
      );
    } else {
      setSelectedMemberRoleResponsibilities(
        teamMemberData.roles
          ?.flatMap((r) => r.roleResponsibilities)
          ?.map((rr) => rr.blueprintRoleResponsibilityId)
          ?.filter((srr) => srr !== roleResponsibilityId)
      );
    }
  };

  const deleteRole = (blueprintRoleId: string) => {
    var roleResponsibilitiesToRemove = roleData.roles.find((x) => x.blueprintRoleId === blueprintRoleId)?.roleResponsibilities;
    if (roleResponsibilitiesToRemove === undefined) return;

    var roleResponsibilities = teamMemberData.roles
      ?.flatMap((r) => r.roleResponsibilities)
      ?.map((rr) => rr.blueprintRoleResponsibilityId)
      ?.filter((x) => roleResponsibilitiesToRemove?.findIndex((rr) => rr.blueprintRoleResponsibilityId === x) === -1);
    setSelectedMemberRoleResponsibilities(roleResponsibilities);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className="tableHeader">
            <TableRow>
              <TableCell>Rol</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      {teamMemberData.roles?.length === 0 && (
        <TableContainer key={"norolesassigned"} component={Paper} sx={{ mt: "1rem" }}>
          <Table>
            <TableBody>
              <TableRow className="tableRow">
                <TableCell>Nog geen rollen toegewezen.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {roleData.roles
        .filter((role) => teamMemberData.roles?.findIndex((r) => r.blueprintRoleId === role.blueprintRoleId) !== -1)
        .map((role) => (
          <TableContainer component={Paper} sx={{ mt: "1rem" }} key={`rolecontainer_for_${role.name}`}>
            <Table>
              <TableHead className="tableHeader">
                <TableRow key={role.name}>
                  <TableCell>{role.name}</TableCell>
                  <TableCell>Fase</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => deleteRole(role.blueprintRoleId)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {role.roleResponsibilities.map((rr) => (
                  <TableRow key={rr.name} className="tableRow">
                    <TableCell>{rr.name}</TableCell>
                    <TableCell>{rr.phaseName}</TableCell>
                    <TableCell align="right">
                      <Switch
                        checked={
                          teamMemberData.roles
                            ?.flatMap((r) => r.roleResponsibilities)
                            .findIndex((srr) => srr.blueprintRoleResponsibilityId === rr.blueprintRoleResponsibilityId) !== -1
                        }
                        onChange={() => toggleActivateRoleResponsibility(rr.blueprintRoleResponsibilityId)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    </>
  );
}
