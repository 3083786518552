import Breadcrumb from "../../components/Breadcrumb";
import { Accordion, AccordionSummary, Grid, Stack, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiClient, useApi } from "../../api/apiClient";
import { useQuery } from "react-query";
import { GridItemLoadingAndDataChecker } from "../../components/GridItemLoadingAndDataChecker";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useState } from "react";
import styles from "./ScoreOverview.module.scss";
import { ScoreGraph } from "../../components/ScoreGraph";

function getMyContexts() {
  return function () {
    return apiClient["/forms/context/my"].get({});
  };
}

export function ScoreOverview() {
  const [expanded, setExpanded] = useState<string[]>([]);
  const { data: contextData, isLoading: contextsAreLoading } = useQuery(["context/my"], useApi("Evaluaties ophalen", getMyContexts()));

  const handleSetExpanded = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? [...expanded, panel] : expanded.filter((e: string) => e !== panel));
  };

  if (contextsAreLoading || !contextData)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumb path={[{ name: "Home", route: "/" }]} currentPage="Mijn Scores" />
      </Grid>
      <Grid item xs={12}>
        <h1>Mijn Scores</h1>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {contextData.formContexts.map((context) => (
            <Grid item xs={12} sm={6} md={4} sx={{ mb: "1rem" }} key={context.id}>
              <Accordion expanded={expanded.indexOf(context.id) !== -1} onChange={handleSetExpanded(context.id)} className={styles.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={context.id} id={context.id} className={styles.accordionHeader}>
                  <Typography variant="subtitle2" className={styles.header}>
                    {context.name} {contextData.formContexts.filter((c) => c.name === context.name && c.teamName !== context.teamName).length > 0 && `(Team: ${context.teamName})`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ScoreGraph scoreDetails={context.scores} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <GridItemLoadingAndDataChecker isLoading={contextsAreLoading} hasData={contextData.formContexts.length > 0} />
    </Grid>
  );
}
