import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./auth/auth";
import { theme } from "./styles/theme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
