import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

type DeleteTeamMemberDialogProps = {
  open: boolean;
  handleClose: (isCancelled: boolean) => void;
  teamMember: string;
  team: string;
};

export function DeleteTeamMemberDialog({ open, handleClose, teamMember, team }: DeleteTeamMemberDialogProps) {
  return (
    <Dialog open={open} onClose={() => handleClose(true)}>
      <DialogTitle>Opgelet</DialogTitle>
      <DialogContent>
        Met deze actie verwijder je het teamlid {teamMember} uit team {team}. Ben je zeker?
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => handleClose(true)}>
          Annuleren
        </Button>
        <Button variant="contained" onClick={() => handleClose(false)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
