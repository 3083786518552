import Breadcrumb from "../../components/Breadcrumb";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Grid, Alert, Stack, Checkbox, Button } from "@mui/material";
import { apiClient, useApi } from "../../api/apiClient";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { GridItemLoadingAndDataChecker } from "../../components/GridItemLoadingAndDataChecker";
import { useUser } from "../../components/UserContext";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { FormForAdminForm, PostParameters } from "../../api/types";
import styles from "./EvaluatiesOverview.module.scss";
import { HeaderCell, SortableTable } from "../../components/SortableTable";
import CheckIcon from "@mui/icons-material/Check";
import { EvaluatiesOverviewFilter } from "./EvaluatiesOverviewFilter";
import { useState } from "react";

function getAllForms() {
  return function () {
    return apiClient["/forms"].get({});
  };
}

function setIgnoreForm({ path }: PostParameters<"/forms/{formKey}/ignore/{isIgnored}">) {
  return apiClient["/forms/{formKey}/ignore/{isIgnored}"].post({
    path: path,
  });
}

function postResendForm(formKey: string) {
  return apiClient["/forms/resend"].post({
    query: { formKey: formKey },
  });
}

type EvaluatiesOverviewProps = RouteComponentProps<{
  aggregateKey: string;
}>;

export function EvaluatiesOverview(props: EvaluatiesOverviewProps) {
  const { aggregateKey: teamKey } = props.match.params;
  const [filteredEvaluaties, setFilteredEvaluaties] = useState<FormForAdminForm[]>([]);
  const queryClient = useQueryClient();
  const { isAdmin } = useUser();

  const { data: formData, isLoading: formsAreLoading } = useQuery(["forms/all", teamKey], useApi("Evaluaties ophalen", getAllForms()));

  const { mutate: resendForm } = useMutation(useApi("Evaluatie hersturen", postResendForm));

  const { mutate: ignoreForm } = useMutation(useApi("Evaluatie genegeerd", setIgnoreForm), {
    onMutate: () => queryClient.cancelQueries("forms/all"),
    onSettled: () => queryClient.invalidateQueries("forms/all"),
  });

  const handleSetIgnore = (formKey: string, isIgnored: boolean) => {
    ignoreForm({
      path: { formKey: formKey, isIgnored: isIgnored },
    });
  };

  function executeResendForm(formKey: string) {
    resendForm(formKey);
  }

  const headers: HeaderCell<FormForAdminForm>[] = [
    {
      id: "assessPersonName",
      label: "Medewerker",
    },
    {
      id: "evaluatorName",
      label: "Evaluator",
    },
    {
      id: "teamName",
      label: "Team",
    },
    {
      id: "sendOn",
      label: "Verstuurd",
      isDate: true,
    },
    {
      id: "year",
      label: "Periode",
    },
    {
      id: "label",
      label: "Context",
    },
    {
      id: "submitted",
      label: "Ingediend",
      render: (form) => <>{form.submitted && <CheckIcon className={styles.submittedCheckIcon} />}</>,
    },
    {
      id: "isIgnored",
      label: "Negeren",
      render: (form) => <Checkbox checked={form.isIgnored} onChange={(e: any) => handleSetIgnore(form.aggregateKey, e.target.checked)} />,
    },
    {
      id: "evaluatorAccessKey",
      label: "Evaluatie",
      render: (form) => (
        <>
          {(form.submitted || isAdmin) && (
            <Link to={`/form/${form.evaluatorAccessKey}`} target="_blank" className={styles.linkButton}>
              <Button>Open Evaluatie</Button>
            </Link>
          )}
        </>
      ),
      disableSorting: true,
    },
    {
      id: "evaluatorAccessKey",
      label: "",
      render: (form) => <>{!form.submitted && <Button onClick={() => executeResendForm(form.aggregateKey)}>Hersturen</Button>}</>,
      disableSorting: true,
    },
  ];

  if (formsAreLoading || !formData)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  if (!isAdmin) return <Alert severity="error">Je moet admin zijn om deze pagina te mogen bekijken.</Alert>;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumb path={[{ name: "Home", route: "/" }]} currentPage="Evaluatie overzicht" />
      </Grid>
      <Grid item xs={12}>
        <h1>Alle Evaluaties</h1>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        {!formsAreLoading && <EvaluatiesOverviewFilter evaluations={formData.forms ?? []} setFilteredEvaluations={setFilteredEvaluaties} />}
      </Grid>
      <Grid item xs={12}>
        <SortableTable<FormForAdminForm> headers={headers} rows={filteredEvaluaties} defaultSortedOn="sendOn" defaultSortedDesc />
      </Grid>
      <GridItemLoadingAndDataChecker isLoading={formsAreLoading} hasData={filteredEvaluaties.length > 0} />
    </Grid>
  );
}
