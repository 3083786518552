import Typography from "@mui/material/Typography";
import { apiClientWithPossibleAuth, useApi } from "../../api/apiClient";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { EditFormAsEvaluator } from "./EditFormAsEvaluator";
import { EditFormAsAdmin } from "./EditFormAsAdmin";
import { Stack } from "@mui/material";
import { useEffect } from "react";

function getForm(accessKey: string) {
  return function () {
    return apiClientWithPossibleAuth["/forms/{accessKey}"].get({
      path: {
        accessKey: accessKey,
      },
    });
  };
}

type EditFormProps = {
  accessKey: string;
};

export function EditForm() {
  const { accessKey } = useParams<EditFormProps>();
  const { data, isLoading } = useQuery(["Form/edit", accessKey], useApi("Mijn formulier ophalen", getForm(accessKey)));

  useEffect(() => {
    if (data) {
      var isSelfEvaluation = data.assessedPerson === data.evaluator;
      document.title = `${data.assessedPerson} - ${isSelfEvaluation ? "Zelfevaluatie" : "Evaluatie"}`;
    }
  });

  if (!data || isLoading)
    return (
      <Stack direction="row" justifyContent="center">
        <LoadingIndicator />
      </Stack>
    );

  if (!data.showForm)
    return (
      <Typography variant="h6" align="center">
        <p>Je voldoet niet aan de vereisten om deze evaluatie te mogen bekijken.</p>
        <p>Mogelijks moet je je zelfevaluatie nog invullen of heeft de evaluator je evaluatie nog niet ingediend.</p>
      </Typography>
    );

  return (
    <>
      {data!.isAdmin && data!.disabled && <EditFormAsAdmin formData={data} />}
      {(!data!.isAdmin || (data!.isAdmin && !data!.disabled)) && <EditFormAsEvaluator formData={data} />}
    </>
  );
}
