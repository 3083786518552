import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Evaluator, RoleResponsibility } from "../../../api/types";
import { MultiSelect } from "../../../components/MultiSelect";
import { distinct } from "../../../util";

type RoleDetailFilterProps = {
  roleResponsibilities: RoleResponsibility[];
  setFilteredRoleResponsibilities: (roleRes: RoleResponsibility[]) => void;
};

const empty = "empty";

const filterPhase =
  (selectedPhaseFilters: string[]) =>
  (rr: RoleResponsibility): boolean => {
    if (!selectedPhaseFilters.length) return true;
    if (rr.phaseName) {
      return selectedPhaseFilters.includes(rr.phaseName);
    }
    return selectedPhaseFilters.includes(empty);
  };

const filterEvaluators =
  (selectedEvaluatorFilters: string[]) =>
  (rr: RoleResponsibility): boolean => {
    if (!selectedEvaluatorFilters.length) return true;
    if (rr.evaluators && rr.evaluators.length) {
      return rr.evaluators.some((e) => selectedEvaluatorFilters.includes(e.blueprintRoleId!));
    }
    return selectedEvaluatorFilters.includes(empty);
  };

const filterStatus =
  (selectedStatusFilters: string[]) =>
  (rr: RoleResponsibility): boolean => {
    if (!selectedStatusFilters.length) return true;
    //TODO: check if there are questions for status "Nieuw"
    if (typeof rr.isActive === "boolean") {
      return selectedStatusFilters.includes(rr.isActive ? "active" : "inactive");
    }
    return true;
  };

export function RoleDetailFilter({ roleResponsibilities, setFilteredRoleResponsibilities }: RoleDetailFilterProps) {
  const [selectedPhaseFilters, setSelectedPhaseFilters] = useState<string[]>([]);
  const [selectedEvaluatorFilters, setSelectedEvaluatorFilters] = useState<string[]>([]);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<string[]>([]);

  const phaseFilters = roleResponsibilities
    .map((rr) => rr.phaseName)
    .filter((p): p is string => !!p)
    .filter(distinct())
    .map((x) => ({ key: x, value: x }));
  phaseFilters.push({ key: empty, value: "(Geen)" });

  const evaluatorFilters = roleResponsibilities
    .map((rr) => rr.evaluators)
    .filter((e): e is Evaluator[] => !!e)
    .flat()
    .map((x) => ({ key: x.blueprintRoleId!, value: x.name! }))
    .filter(distinct((x) => x.key));
  evaluatorFilters.push({ key: empty, value: "(Geen)" });

  const statusFilters = [
    { key: "new", value: "Nieuw" },
    { key: "active", value: "Actief" },
    { key: "inactive", value: "Inactief" },
  ];

  useEffect(
    () =>
      setFilteredRoleResponsibilities(
        roleResponsibilities.filter(filterPhase(selectedPhaseFilters)).filter(filterEvaluators(selectedEvaluatorFilters)).filter(filterStatus(selectedStatusFilters))
      ),
    [selectedPhaseFilters, selectedEvaluatorFilters, selectedStatusFilters, roleResponsibilities, setFilteredRoleResponsibilities]
  );

  const removeFilters = () => {
    setSelectedEvaluatorFilters([]);
    setSelectedPhaseFilters([]);
    setSelectedStatusFilters([]);
  };

  return (
    <>
      {roleResponsibilities.length > 0 && (
        <Stack direction="row" spacing={1}>
          {phaseFilters.length > 0 && <MultiSelect name="Fase" selectedItems={selectedPhaseFilters} setSelectedItems={setSelectedPhaseFilters} allItems={phaseFilters} />}
          {evaluatorFilters.length > 0 && (
            <MultiSelect name="Evaluator" selectedItems={selectedEvaluatorFilters} setSelectedItems={setSelectedEvaluatorFilters} allItems={evaluatorFilters} />
          )}
          {statusFilters.length > 0 && <MultiSelect name="Status" selectedItems={selectedStatusFilters} setSelectedItems={setSelectedStatusFilters} allItems={statusFilters} />}
          <Button color="secondary" variant="outlined" onClick={removeFilters}>
            Reset filters
          </Button>
        </Stack>
      )}
    </>
  );
}
