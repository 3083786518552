import { useQuery } from "react-query";
import { apiClient, useApi } from "../../../api/apiClient";

async function getQuestionDetail(roleResponsibilityId: string, questionKey?: string) {
  return await apiClient["/roleresponsibilities/{blueprintRoleResponsibilityId}/questions/{id}"].get({
    path: { id: questionKey || "", blueprintRoleResponsibilityId: roleResponsibilityId },
  });
}

export function useGetQuestionDetail(roleresponsibilityId: string, questionKey?: string) {
  return useQuery(
    ["getQuestionDetail", questionKey, roleresponsibilityId],
    useApi("Vraag detail ophalen", () => getQuestionDetail(roleresponsibilityId, questionKey))
  );
}
